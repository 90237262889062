table.loadtime_info {
  margin: 1em;
  border-collapse: collapse;

  td, th {
    padding: 4px;
    text-align: left;
    font-size: 12px;
    border: 1px solid $gray-400;
  }

  th {
    background: $gray-200;
  }

  tr.timeclass100 {
    background: #ffc;
  }

  tr.timeclass200 {
    background: #ffff7a;
  }

  tr.timeclass500 {
    background: #ffc145;
  }

  tr.timeclass1000 {
    background: #ff6c6c;
  }
}