#breadcrumb {
  margin: 12px 0 16px;

  a {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-grey;

      i {
        color: $color-black;
      }
    }

    &:last-child {
      margin-right: 0;
      font-weight: 700;
    }

    i {
      margin-left: 12px;
      font-size: 9px;
      transform: translateY(-1px);
      transition: all 0.3s ease-in-out;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}