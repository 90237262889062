#cart-items {
  border-bottom: 1px solid $color-grey2;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;

  .item {
    padding-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid $color-grey2;

    &:last-child {
      border-bottom: 0;
    }

    .details {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      column-gap: 12px;

      .img-wrp {
        flex-shrink: 0;
        line-height: 0;

        a {
          display: block;
        }

        img {
          width: 83px;
          height: 80px;
          object-fit: contain;
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }

    .content-inner {
      display: flex;
      flex-direction: column;
      width: 91%;
      padding-right: 15px;

      .title {
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .code {
        color: $color-black;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
      }
    }

    .info-bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-top: 24px;

      .cart-remove-wrp {
        display: none;
      }

      .qty {
        white-space: nowrap;
      }

      .quantity-selector {
        input {
          width: 38px;
          padding: 0;
        }
      }
    }

    .cart-remove-wrp {
      width: 9%;

      .cart-remove {
        color: $color-black;
        font-size: 20px;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }
      }
    }

    .price-info {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &.sum-info {
        margin-top: 10px;

        .price {
          font-size: 16px;
        }

        .old-price {
          line-height: 16px;
        }
      }

      .price {
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 12px;
        font-style: normal;
        line-height: 1;

        &.discount {
          color: $color-orange;
        }
      }

      .old-price {
        margin-left: 6px;
        color: $color-black;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-decoration: line-through;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    .item {
      .info-bottom {
        flex-direction: row;
        align-items: center;
      }

      .price-info {
        &.sum-info {
          margin-top: 0;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    .item {
      padding-top: 24px;

      .details {
        align-items: stretch;
        column-gap: 20px;

        .img-wrp {
          img {
            width: 108px;
            height: 104px;
          }
        }
      }

      .content {
        flex-wrap: nowrap;
      }

      .content-inner {
        width: auto;
        max-width: 255px;
        padding-right: 0;
        margin-right: auto;

        .title {
          margin-bottom: 2px;
        }
      }

      .cart-remove-wrp {
        display: none;
      }

      .info-bottom {
        width: 54%;
        margin-top: 0;
        justify-content: flex-end;

        .cart-remove-wrp {
          display: flex;
          align-items: center;
          width: auto;
          margin-left: auto;
        }

        .quantity-selector {
          input {
            width: 66px;
          }
        }
      }

      .price-info {
        margin-top: 10px;

        &.sum-info {
          flex-direction: column;
          align-items: flex-end;
          width: 135px;

          .old-price {
            margin-left: 0;
          }
        }

        .price {
          font-size: 16px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .item {
      .content-inner {
        max-width: 320px;
      }

      .info-bottom {
        width: 43.6%;
      }

      .price-info {
        &.sum-info {
          width: 167px;
        }
      }
    }
  }
}