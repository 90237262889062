.customers-authorize-page {
  margin-top: 64px;

  .page-title {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @include media-breakpoint-up(md) {
    max-width: 438px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;

    .page-title {
      margin-top: 0;
      margin-bottom: 41px;
    }
  }
}