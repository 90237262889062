#cart-detailed {
  padding-top: 32px;
  margin-bottom: 80px;

  .page-header {
    margin-bottom: 16px;
  }

  .cart-summary-wrp {
    margin-top: 32px;

    #cart-vouchers {
      margin-bottom: 32px;
    }

    .btn-wrp {
      margin-top: 28px;

      .button {
        width: 100%;

        i {
          margin-left: 10px;
          font-size: 13px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 1093px;
    padding-top: 48px;
    margin-bottom: 0;

    .page-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .page-title {
        font-size: 44px;
        line-height: 40px;
      }

      .button {
        margin-left: 16px;

        i {
          margin-left: 12px;
          font-size: 13px;
        }
      }
    }

    .cart-summary-wrp {
      max-width: 407px;
      margin-top: 48px;
      margin-left: auto;
    }
  }
}