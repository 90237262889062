#checkout-delivery {
  #delivery-type-options {
    h2 {
      margin-bottom: 8px;
    }
  }

  .wrp_terminals{
    .bootstrap-select{
      .dropdown-menu{
        li{
          a{
            white-space: normal;
          }
        }
      }
    }
  }

  .delivery-types {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .delivery-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 12px 14px 42px;
      margin: 0;
      background: rgba($color-grey5, 0.5);
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: $color-grey5;
      }

      &.active {
        background: $color-grey5;

        .box {
          border-width: 5px;
        }
      }

      .box {
        position: absolute;
        top: 50%;
        left: 12px;
        z-index: 1;
        width: 18px;
        height: 18px;
        border: 1px solid $color-black;
        border-radius: 100%;
        transform: translateY(-50%);
      }

      .content {
        display: flex;
        flex-direction: column;

        .title {
          margin-bottom: 2px;
          color: $color-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .desc {
          color: $color-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .price {
        margin-left: 10px;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        white-space: nowrap;

        .price-txt{
          font-family: $font-family-base;
          font-weight: 700;
        }
      }
    }
  }

  #delivery-info {
    margin-top: 56px;
  }

  @include media-breakpoint-up(md) {
    #delivery-type-options {
      h2 {
        margin-bottom: 16px;
      }
    }

    .delivery-types {
      .delivery-item {
        padding-left: 56px;
        padding-right: 24px;

        .box {
          left: 20px;
        }
      }
    }

    #delivery-info {
      margin-top: 48px;
    }
  }
}