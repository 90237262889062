.fancybox-navigation {
  .fancybox-button--arrow_right,
  .fancybox-button--arrow_left {
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 100%;
    background: linear-gradient(to bottom, $color-black 50%, $color-orange 50%) 0 0/200% 200%;
    transition: background-position 400ms, border-color 400ms, color 200ms;
    transform: translateY(-50%);

    &:hover {
      background-position: 0 -100%;
    }

    &[disabled] {
      opacity: 0.5;
    }

    i {
      font-size: 20px;
      color: $color-white;
    }
  }
}