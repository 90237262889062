#order-department {
  .selected-service {
    display: flex;
    min-height: 76px;
    margin-bottom: 24px;
    background: rgba($color-grey5, 0.5);

    &:hover {
      .icon {
        background-position: 0 -100%;
      }
    }

    .content {
      display: flex;
      column-gap: 16px;
      align-items: flex-start;
      flex-grow: 1;
      padding: 16px 16px 16px 20px;
    }

    .house {
      margin-top: 3px;
      color: $color-black;
      font-size: 26px;
    }

    .text {
      display: flex;
      flex-direction: column;

      .title {
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .address {
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      background: linear-gradient(to bottom, $color-black 50%, $color-orange 50%) 0 0/200% 200%;
      color: $color-white;
      font-size: 12px;
      transition: background-position 400ms, border-color 400ms, color 200ms;
    }
  }

  #service-select-popup {
    position: fixed;
    top: 0;
    right: -16px;
    bottom: 0;
    z-index: $zindex-modal-backdrop;
    padding-top: 48px;
    padding-bottom: 16px;
    max-width: 487px;
    width: 100%;
    background-color: $color-white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.15s ease-in-out;
    overflow: hidden;

    &.open {
      right: 0;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    #service-select-hide {
      position: absolute;
      top: 14px;
      right: 7px;
      padding: 9px;
      border: 0;
      background: none;
      font-size: 14px;
      line-height: 0;
      color: $color-black;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: $color-grey5;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-left: 16px;
      padding-right: 16px;

      h3 {
        margin: 0 0 25px;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 22px;
        font-style: normal;
        line-height: normal;
      }

      .services-list {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        overflow: auto;
        @include scrollbar();

        .service-item {
          position: relative;
          display: flex;
          flex-direction: column;
          padding: 16px 32px 16px 16px;
          background: rgba($color-grey5, 0.5);

          .icon {
            position: absolute;
            top: 50%;
            right: 16px;
            color: $color-black;
            font-size: 13px;
            transform: translateY(-50%);
          }

          .title {
            color: $color-black;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .address {
            color: $color-black;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .working-hours {
            display: flex;
            flex-direction: column;
            margin-top: 16px;

            .title {
              color: $color-black;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .times {
              color: $color-black;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    h3 {
      margin-bottom: 16px;
    }

    .selected-service {
      margin-bottom: 34px;
    }

    #service-select-popup {
      #service-select-hide {
        top: 21px;
        right: 23px;
      }

      .content {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }
}