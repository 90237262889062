#customers-authorize {
  .page-title {
    margin-bottom: 12px;
  }

  .info-top {
    margin-bottom: 28px;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .info-bottom {
    margin-top: 16px;
    margin-bottom: 0;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .form-item {
    margin-bottom: 22px;
  }

  .button {
    width: 100%;

    i {
      margin-left: 12px;
      font-size: 13px;
    }
  }

  .or-text {
    margin-top: 16px;
    margin-bottom: 16px;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  #remind_pass {
    color: $color-orange;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-grey;
    }

    i {
      margin-right: 8px;
      font-size: 12px;
    }
  }

  #remind_pass {
    color: $color-orange;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-grey;
    }
  }

  @include media-breakpoint-up(md) {
    .page-title {
      margin-bottom: 9px;
    }
  }
}