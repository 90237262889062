body {
  height: 100%;
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: 1.1;
  color: $color-black;

  &.main-nav-active {
    position: fixed;
    width: 100%;
  }
}

#content-layout {
  padding-top: 81px;

  &.content-layout-checkout {
    padding-top: 49px;
  }

  &.content-layout-products {
    margin-bottom: 80px;
  }

  @include media-breakpoint-up(md) {
    &.content-layout-checkout {
      padding-top: 66px;
    }

    &.content-layout-products {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 117px;
  }
}

* {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0.5em 0;
  font-family: $font-family-stihl-bold;
}

.page-title {
  margin: 0;
  color: $color-black;
  font-size: 28px;
  font-style: normal;
  line-height: 32px;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    font-size: 32px;
    line-height: 41px;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.no-scroll {
  overflow: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}

#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba($color-black, 0.6);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }

    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
    }

    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
    }
  }
}

.no-transition {
  transition: none;
}

.icon-discount {
  position: relative;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: $color-orange;
  color: $color-white;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;

  &:after {
    content: '%';
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $zindex-fixed;
  background: rgba($color-black, 0.3);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all .15s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.html-content {
  color: $color-black;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h2 {
    margin-top: 16px;
    margin-bottom: 16px;
    color: $color-black;
    font-family: $font-family-stihl-bold;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
  }

  p {
    margin-bottom: 16px;
  }

  a:not(.button):not(.btn) {
    color: $color-orange;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-grey;
    }
  }

  @include media-breakpoint-up(md) {
    h2 {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.page-content {
  .page_editor_template:not(.full-width),
  .collections-index-listing,
  .page-title {
    max-width: 976px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .page_editor_template,
  .collections-index-listing {
    margin-bottom: 40px;
  }
}

.list-anchor__link {
  color: $color-black !important;
  font-weight: 700;
  box-shadow: inset 0 -.05em $color-white, inset 0 -.15em $color-orange;
}

.link_wt_chev {
  a {
    position: relative;
    display: inline-block;
    padding-right: 20px;
    color: $color-black !important;
    line-height: 1.1;
    font-family: $font-family-stihl-bold;

    &:hover {
      &:after {
        transform: translateY(-50%) translateX(10px);
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 5px;
      content: "";
      margin-left: 5px;
      width: 12px;
      height: 12px;
      background: url(/images/frontend/icon-arrow-right-16.svg) no-repeat 50% / contain;
      transition: transform .2s;
      transform: translateY(-50%);
    }
  }
}

@include media-breakpoint-up(lg) {
  .d-lg-grid {
    display: grid !important;
  }

  .fancybox-content {
    width: 70%;
  }
}

//TEMP
[data-lastpass-root] {
  display: none !important;
}

.fancybox-slide--html{
  .fancybox-content{
    padding: 40px 0;

    .page-title,
    .page_editor_template{
      max-width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    .fancybox-content{
      padding: 40px;
    }
  }
}