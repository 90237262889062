.add-to-comparison {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $color-grey;
  }

  &.active {
    span {
      display: block;
    }
  }

  span {
    position: absolute;
    top: 4px;
    right: 6px;
    z-index: 1;
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $color-orange;
  }

  @include media-breakpoint-up(lg) {
    padding: 8px;
  }
}