@mixin icomoon() {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin scrollbar() {
  &::-webkit-scrollbar-track {
    background-color: $color-grey5;
    border-radius: 14px;
    border: 4px solid $color-white;
  }

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-black;
    border-left: 4px solid $color-white;
    border-right: 4px solid $color-white;
    border-radius: 6px;
  }
}