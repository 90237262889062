.button, .btn-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border: 0;
  padding-left: 28px;
  padding-right: 28px;
  color: $color-white;
  font-family: $font-family-stihl-bold;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(to bottom, $color-orange 50%, $color-black 50%) 0 0/200% 200%;
  transition: background-position 400ms, border-color 400ms, color 200ms;

  &:hover {
    background-position: 0 -100%;
    color: $color-white;
  }

  &:disabled, &.disabled {
    background: $color-white-darker !important;
    cursor: not-allowed;
  }

  &.inverted {
    background: linear-gradient(to bottom, $color-black 50%, $color-orange 50%) 0 0/200% 200%;

    &:hover {
      background-position: 0 -100%;
    }
  }

  &.white {
    background: linear-gradient(to bottom, $color-white 50%, $color-black 50%) 0 0/200% 200%;
    color: $color-black;

    &:hover {
      background-position: 0 -100%;
      color: $color-white;
    }

    &:disabled, &.disabled {
      color: $color-black;
    }
  }

  &.bordered {
    border: 1px solid $color-white;
    background: linear-gradient(to bottom, transparent 50%, $color-black 50%) 0 0/200% 200%;

    &:hover {
      background-position: 0 -100%;
    }

    &.grey {
      border-color: $color-grey;
      color: $color-grey;

      &:hover {
        border-color: $color-black;
        color: $color-white;
      }

      &:disabled, &.disabled {
        color: $color-grey;
      }
    }

    &.simple {
      border-color: $color-grey;
      background: none;
      color: $color-grey;

      &:hover {
        border-color: $color-black;
        background: none;
        color: $color-black;
      }

      &:disabled, &.disabled {
        color: $color-black;
      }
    }
  }

  &.size-44 {
    height: 44px;
    font-family: $font-family-base;
    font-weight: 700;
    letter-spacing: -0.28px;
  }

  &.size-48 {
    height: 48px;
  }

  &.size-56 {
    height: 56px;
  }
}