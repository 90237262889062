#products-set {
  margin-bottom: 73px;

  .set-total-price-wrp {
    position: relative;
    padding: 33px 24px 24px 24px;
    background: $color-orange;

    .tag-wrp {
      position: absolute;
      top: 24px;
      left: 24px;
      z-index: 1;
      display: none;
      width: 104px;

      .txt {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 26px;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.804px;
        text-transform: uppercase;
      }
    }

    .title {
      display: block;
      margin-bottom: 9px;
      color: $color-white;
      font-family: $font-family-stihl-bold;
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
    }

    .total-price {
      display: flex;
      align-items: center;
      margin-bottom: 22px;

      .old-price {
        margin-left: 12px;
        color: $color-white;
        font-family: $font-family-stihl-medium;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-decoration: line-through;
      }

      .price {
        color: $color-white;
        font-family: $font-family-stihl-bold;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 24px;
        white-space: nowrap;
      }
    }
  }

  .set-items {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding-bottom: 20px;
    border: 1px solid $color-grey4;
  }

  .item {
    position: relative;
    display: flex;
    align-items: flex-start;
    column-gap: 12px;
    padding: 10px;

    &.disabled {
      .check-item {
        pointer-events: none;
      }
    }

    &.active:not(.disabled) {
      .img-wrp,
      .details {
        opacity: 1;
      }

      .checkbox-item {
        .box {
          background: $color-black;

          i {
            opacity: 1;
          }
        }
      }
    }

    .checkbox-item {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 10px;

      .box {
        margin-right: 0;
      }
    }

    .img-wrp {
      display: block;
      flex-shrink: 0;
      line-height: 0;
      opacity: 0.2;

      img {
        width: 100px;
        height: 97px;
        object-fit: contain;
      }
    }

    .details {
      opacity: 0.2;
    }

    .product-name {
      display: block;
      color: $color-black;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }

    .category {
      display: block;
      margin-bottom: 7px;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
    }

    .shot-desc {
      display: block;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .price-info {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .out-of-stock {
        display: block;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
      }

      .price {
        display: block;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 12px;
        font-style: normal;
        line-height: 1;

        &.discount {
          color: $color-orange;
        }
      }

      .old-price {
        margin-left: 8px;
        color: $color-black;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        text-decoration: line-through;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .item {
      display: flex;
      align-items: center;
      column-gap: 30px;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    min-height: 460px;
    margin-bottom: 100px;

    .set-total-price-wrp {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      order: 1;
      flex-shrink: 0;
      width: 23.5%;

      .tag-wrp {
        display: block;
      }

      .total-price {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 48px;

        .price {
          order: 2;
        }

        .old-price {
          order: 1;
          margin-left: 0;
        }
      }
    }

    .set-items {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 30px;
      padding-left: 30px;
      padding-right: 16px;
      padding-bottom: 0;
      order: 2;
      flex-grow: 1;
    }

    .item {
      flex-direction: column;
      justify-content: space-between;
      grid-gap: 0;
      padding: 23px 0 36px;

      .checkbox-item {
        top: 32px;
        padding-left: 0;
      }

      .img-wrp {
        img {
          width: 100%;
          height: auto;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        margin-top: 4px;
      }

      .category {
        margin-bottom: 18px;
      }

      .shot-desc {
        margin-bottom: 0;
        font-size: 15px;
        line-height: 20px;
      }

      .price-info {
        margin-top: 18px;

        .price {
          font-size: 16px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .set-items {
      column-gap: 56px;
      padding-left: 40px;
    }
  }
}