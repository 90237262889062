@use "sass:map";

.linepromos {
  position: relative;
  z-index: 5;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 13px 15px;
    height: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 18px;

    p {
      margin-bottom: 0;
    }
  }

  .owl-carousel {
    .owl-stage {
      display: flex;
      flex-direction: row;

      &:after {
        display: none;
      }

      .owl-item {
        float: none;
      }
    }

    .owl-nav button {
      &.owl-prev, &.owl-next {
        margin-top: 0;
        padding: 5px !important;
        background: none;
        color: $white;


        &:hover {
          background: none !important;
        }
      }
    }
  }
}