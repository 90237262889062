.quantity-selector {
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 30px;
    padding: 0;
    border: 0;
    background: linear-gradient(to bottom, $color-black 50%, $color-orange 50%) 0 0/200% 200%;
    transition: background-position 400ms, border-color 400ms, color 200ms;
    font-size: 10px;
    color: $color-white;

    &:hover {
      background-position: 0 -100%;
    }
  }

  input {
    width: 66px;
    height: 30px;
    font-family: $font-family-stihl-medium;
    text-align: center;
  }
}