.collections-index-listing {
  .list {
    display: flex;
  }

  .product-element {
    flex-shrink: 0;
  }

  .collection-banner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 188px;
    padding: 12px;
    margin-bottom: 32px;
    background: $color-orange;

    &:hover {
      .text-wrp {
        .arrow-circle {
          background: $color-black;

          i {
            color: $color-white;
          }
        }
      }
    }

    .tag-wrp {
      position: relative;
      display: block;
      width: 88px;

      .txt {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 26px;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.804px;
        text-transform: uppercase;
      }
    }

    .text-wrp {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 16px;

      .title {
        max-width: 210px;
        margin-right: 10px;
        color: $color-white;
        font-weight: 700;
        font-size: 20px;
        font-style: normal;
        line-height: 24px;
      }
    }
  }

  .list-wrp {
    .collection-banner {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    &.display-banner {
      .list-wrp {
        display: flex;

        .collection-banner {
          display: flex;
          width: 23.8%;
          height: auto;
          margin-right: 30px;
          margin-bottom: 0;
        }

        .tns-outer {
          width: 76.2%;
        }
      }
    }

    .collection-banner {
      display: none;
      height: 100%;
      padding: 24px;

      .tag-wrp {
        width: 120px;

        .txt {
          font-size: 26px;
          letter-spacing: -0.804px;
        }
      }
    }
  }
}