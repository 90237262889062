#site-footer {
  .footer-badges {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    padding-top: 40px;
    padding-bottom: 40px;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 48px;
        height: auto;
      }

      span {
        display: block;
        max-width: 190px;
        margin-top: 8px;
        color: $color-black;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
      }
    }
  }

  .footer-social-wrp {
    background: $color-orange;
    padding-bottom: 64px;

    .social-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 25px;

      .icon {
        font-size: 24px;
        color: $color-white;
        transition: all 0.3s ease-in-out;

        &:hover:not(span) {
          color: $color-black;
        }

        &.e-icon-youtube {
          font-size: 20px;
        }

        &.e-icon-instagram {
          font-size: 25px;
        }

        &.e-icon-facebook,
        &.e-icon-linkedin {
          font-size: 23px;
        }

        &.stihl {
          font-family: $font-family-stihl-bold;
          font-size: 21px;
          font-style: normal;
          line-height: 18.508px;
          letter-spacing: -0.846px;
        }
      }
    }
  }

  #footer-bottom {
    padding-top: 37px;
    padding-bottom: 20px;
    background: $color-white-darker;

    .container-fluid {
      max-width: $container-fluid-width;
    }

    #footer-menu {
      margin-bottom: 24px;
    }

    .footer-menu-column {
      border-bottom: 1px solid $color-grey2;

      .title {
        margin: 0;
        font-family: $font-family-base;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.32px;
        text-transform: uppercase;

        a {
          position: relative;
          display: block;
          padding-top: 19px;
          padding-bottom: 19px;
          color: $color-black;

          &[aria-expanded="true"] {
            i {
              transform: translateY(-50%) rotate(180deg);
            }
          }

          i {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 1;
            font-size: 8px;
            color: $color-black;
            transform: translateY(-50%);
            transform-origin: center center;
          }
        }
      }

      .menu-list {
        ul {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          padding-bottom: 28px;

          li {
            a {
              display: block;
              color: $color-black;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }
        }
      }
    }

    .payments {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      grid-gap: 2px;

      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 31px;
        background: $color-white;

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid rgba($color-grey3, 0.5);
          content: '';
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    #copyright {
      margin-top: 32px;
      color: $color-black;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: center;

      a {
        color: $color-black;
      }

      .copyright {
        margin-bottom: 4px;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    .footer-social-wrp {
      .social-icons {
        grid-gap: 32px;
      }
    }

    #footer-bottom {
      .payments {
        grid-template-columns: repeat(6, minmax(0, 1fr));
      }
    }
  }
  @include media-breakpoint-up(md) {
    .footer-badges {
      flex-direction: row;
      justify-content: center;
      column-gap: 35px;
      padding-top: 116px;
      padding-bottom: 55px;

      .item {
        width: 228px;

        img {
          width: 68px;
        }

        span {
          margin-top: 24px;
        }
      }
    }

    .footer-social-wrp {
      padding-bottom: 72px;
    }

    #footer-bottom {
      padding-bottom: 43px;

      #footer-menu {
        margin-bottom: 43px;
      }

      .payments {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 8px;

        li {
          width: 67px;
          height: 40px;
        }
      }

      #copyright {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 32px;
        margin-top: 32px;
        border-top: 1px solid rgba($color-grey4, 0.7);

        .copyright {
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .footer-badges {
      column-gap: 80px;
    }

    #footer-bottom {
      padding-top: 60px;

      #footer-menu {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 50px;
      }

      .footer-menu-column {
        border-bottom: 0;

        .title {
          a {
            cursor: text;
          }
        }

        .menu-list {
          display: block !important;
          height: auto !important;

          ul {
            padding-bottom: 0;
            row-gap: 12px;
          }
        }
      }

      #copyright {
        margin-top: 46px;
      }
    }
  }
}