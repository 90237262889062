.alert {
  position: relative;
  margin: 0 0 16px;
  border: 0;
  padding: 10px 10px 10px 38px;
  font-family: $font-family-base;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &.alert-success {
    background: $color-green2;
    color: $color-green3;

    &:before {
      position: absolute;
      top: 14px;
      left: 12px;
      content: "\e901";
      font-size: 12px;
      @include icomoon();
    }
  }

  &.alert-danger {
    background: $color-red3;
    color: $color-red2;

    &:before {
      position: absolute;
      top: 13px;
      left: 12px;
      content: "\e921";
      font-size: 16px;
      @include icomoon();
    }
  }

  &.alert-warning {
    background: $color-yellow;
    color: $color-yellow2;

    &:before {
      position: absolute;
      top: 13px;
      left: 12px;
      content: "\e920";
      font-size: 14px;
      @include icomoon();
    }
  }

  &.alert-info {
    background: $color-blue;
    color: $color-blue2;

    &:before {
      position: absolute;
      top: 13px;
      left: 12px;
      content: "\e91a";
      font-size: 16px;
      @include icomoon();
    }
  }

  &.alert-dismissible {
    padding-right: 40px;

    .close {
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      padding: 9px;
      font-size: 14px;
      line-height: 0;
      color: $color-black;
      transition: all 0.3s ease-in-out;


      &:hover {
        opacity: 1;
      }
    }
  }


  @include media-breakpoint-up(md) {
    padding: 16px 16px 16px 38px;

    &.alert-success {
      &:before {
        top: 22px;
        left: 16px;
        font-size: 10px;
      }
    }

    &.alert-danger {
      &:before {
        top: 18px;
        left: 16px;
      }
    }

    &.alert-warning {
      &:before {
        top: 18px;
        left: 16px;
      }
    }

    &.alert-info {
      &:before {
        top: 18px;
        left: 16px;
      }
    }
  }
}