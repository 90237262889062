.hero-2cols-wrp {
  margin-bottom: 48px;

  .hero-2-columns {
    .hero-content-small,
    .hero-content-large {
      position: relative;
      min-height: 380px;

      &.color-theme-dark {
        .content {
          h3 {
            color: $color-black;

            &:after {
              background-color: $color-black;
            }
          }

          .desc {
            color: $color-black;
          }
        }
      }

      .img-wrp {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        line-height: 0;

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(5deg, rgba($color-black, 0.6) 24.14%, rgba($color-black, 0) 78.98%);
          content: '';
        }

        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        padding: 24px 16px;

        h3 {
          margin: 0 0 8px;
          color: $color-white;
          font-size: 32px;
          font-style: normal;
          line-height: 32px;
          text-transform: uppercase;
        }

        .desc {
          margin-bottom: 0;
          color: $color-white;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .button {
          margin-top: 20px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .hero-2-columns {
      .hero-content-small,
      .hero-content-large {
        .content {
          max-width: 438px;
          padding: 40px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;

    .hero-2-columns {
      display: flex;
      max-width: 1440px;
      margin: 0 auto;

      &.display_desktop_75_25 {
      }

      .hero-content-small,
      .hero-content-large {
        min-height: 500px;

        .img-wrp {
          &:after {
            background: linear-gradient(7deg, rgba($color-black, 0.6) 14.31%, rgba($color-black, 0) 77.68%);
          }
        }

        .content {
          h3 {
            margin-bottom: 16px;
          }

          .button {
            margin-top: 28px;
          }
        }
      }

      .hero-content-small {
        width: 36%;

        .content {
          max-width: 100%;
        }
      }

      .hero-content-large {
        width: 64%;

        .content {
          max-width: 600px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .hero-2-columns {
      .hero-content-small,
      .hero-content-large {
        .content {
          h3 {
            font-size: 44px;
            line-height: 41px;
          }
        }
      }

      .hero-content-small {
        .content {
          padding: 80px 48px;
        }
      }

      .hero-content-large {
        .content {
          padding: 80px;
        }
      }
    }
  }
}