[class^="e-icon-"], [class*=" e-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.e-icon-warning:before {
  content: "\e920";
}

.e-icon-danger:before {
  content: "\e921";
}

.e-icon-disallowed:before {
  content: "\e91f";
}

.e-icon-terminal:before {
  content: "\e91e";
}

.e-icon-alert:before {
  content: "\e91d";
}

.e-icon-house:before {
  content: "\e91c";
}

.e-icon-truck:before {
  content: "\e91b";
}

.e-icon-info:before {
  content: "\e91a";
}

.e-icon-minus:before {
  content: "\e919";
}

.e-icon-trash:before {
  content: "\e918";
}

.e-icon-sort:before {
  content: "\e916";
}

.e-icon-filter:before {
  content: "\e917";
}

.e-icon-left-light:before {
  content: "\e914";
}

.e-icon-right-light:before {
  content: "\e915";
}

.e-icon-plus:before {
  content: "\e913";
}

.e-icon-arrow-up-right-square:before {
  content: "\e912";
}

.e-icon-close:before {
  content: "\e911";
}

.e-icon-cart:before {
  content: "\e90e";
}

.e-icon-user:before {
  content: "\e90d";
}

.e-icon-search:before {
  content: "\e90f";
}

.e-icon-bars:before {
  content: "\e910";
}

.e-icon-location:before {
  content: "\e90c";
}

.e-icon-compare:before {
  content: "\e90b";
}

.e-icon-arrow-left:before {
  content: "\e909";
}

.e-icon-arrow-right:before {
  content: "\e90a";
}

.e-icon-left:before {
  content: "\e908";
}

.e-icon-right:before {
  content: "\e907";
}

.e-icon-down:before {
  content: "\e906";
}

.e-icon-instagram:before {
  content: "\e902";
}

.e-icon-youtube:before {
  content: "\e903";
}

.e-icon-facebook:before {
  content: "\e904";
}

.e-icon-linkedin:before {
  content: "\e905";
}

.e-icon-pen:before {
  content: "\e900";
}

.e-icon-check:before {
  content: "\e901";
}
