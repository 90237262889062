.accordion-styled {
  margin-top: 40px;
  margin-bottom: 40px;

  .card {
    border-bottom: 1px solid $color-black;

    .card-header {
      h5 {
        margin: 0;

        button {
          position: relative;
          display: block;
          width: 100%;
          padding: 20px 15px 20px 0;
          border: 0;
          border-radius: 0;
          background: none;
          color: $color-black;
          font-size: 14px;
          font-family: $font-family-stihl-bold;
          line-height: normal;
          cursor: pointer;

          &.collapsed {
            &:after {
              transform: translateY(-50%);
            }
          }

          &:after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(180deg);
            transform-origin: center center;
            @include icomoon();
            content: "\e906";
            font-size: 10px;
            color: $color-black;
            line-height: 1;
          }
        }
      }
    }

    .card-body {
      margin-bottom: 40px;
    }
  }
}