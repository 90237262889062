.tags {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1px;

  .tag {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 24px;
    text-align: left;
    background: $color-orange;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    color: $color-white;
  }
}