#cart-vouchers {
  .title {
    display: block;
    margin-bottom: 7px;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .used {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-bottom: 7px;

    li {
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      a {
        display: inline-block;
        margin-left: 6px;
        color: $color-black;
        line-height: 0;
      }
    }
  }

  .input-group-wrp {
    display: flex;

    input {
      height: 56px;
      padding: 0 16px;
      border: 1px solid $color-grey2;
      border-right: 0;
      background: $color-white;
      color: $color-grey;
      font-size: 16px;

      &::placeholder {
        color: $color-grey;
      }
    }

    .button {
      width: 56px;
      height: 56px;
      padding: 0;
      flex-shrink: 0;
      font-size: 17px;
    }
  }
}