@use "sass:map";

#products-add2cart {
  form {
    display: flex;
    flex-direction: column;
  }

  .alert {
    margin: 15px 0;
  }

  #inform-in-stock-btn {
    margin-bottom: 24px;
  }

  .price-info {
    margin-bottom: 20px;
    color: $color-black;
    font-family: $font-family-stihl-bold;
    font-size: 32px;
    font-style: normal;
    line-height: 1;

    &.discount {
      .price {
        color: $color-orange;
      }
    }

    .price {
      .price-from {
        font-family: $font-family-base;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        color: $color-black;
      }
    }

    .old-price {
      display: block;
      margin-top: 5px;
      color: $color-black;
      font-family: $font-family-base;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      text-decoration: line-through;
    }
  }

  .items {
    margin-bottom: 20px;

    .item-title {
      display: block;
      margin-bottom: 8px;
      color: $color-black;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .change-item-btn {
      display: flex;
      align-items: stretch;
      min-height: 72px;
      width: 100%;
      padding: 0;
      border: 0;
      background: rgba($color-grey5, 0.5);
      text-align: left;
      transition: all 0.3s ease-in-out;

      &.single {
        pointer-events: none;

        .select-icon {
          display: none;
        }
      }

      &:hover:not(.single) {
        .select-icon {
          background-position: 0 -100%;
        }
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 10px 14px;

        .name {
          color: $color-black;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }

        .code {
          color: $color-black;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .select-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        flex-shrink: 0;
        background: linear-gradient(to bottom, $color-black 50%, $color-orange 50%) 0 0/200% 200%;
        transition: background-position 400ms, border-color 400ms, color 200ms;

        i {
          font-size: 11px;
          color: $color-white;
        }
      }
    }
  }

  .availability-wrp {
    margin-bottom: 10px;

    .title {
      display: block;
      margin-bottom: 8px;
      color: $color-black;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .quantity-wrp {
      display: flex;
      align-items: center;
      column-gap: 16px;
    }

    .stock-status {
      display: flex;
      align-items: center;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;

      &:before {
        content: '';
        display: inline-block;
        margin-right: 6px;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        background-color: $color-green;
        transform: translateY(-1px);
      }

      &.out-of-stock {
        &:before {
          background-color: $color-red;
        }
      }
    }
  }

  .taxes-text {
    margin-top: 4px;
    margin-bottom: 0;
    color: $color-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .leasing-types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 32px;

    .leasing-item {
      .leasing-text {
        display: block;
        text-align: center;
        margin-top: 6px;
        font-size: 12px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 78px;
      padding: 11px 15px;
      border: 1px solid $color-orange;
      background: none;
      appearance: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        border: 1px solid $color-black;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .shipping-wrp {
    .title {
      margin-bottom: 6px;
      color: $color-black;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;

      i {
        font-size: 12px;
      }
    }

    .shipping-info {
      margin-bottom: 8px;
      color: $color-black;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: 700;

        i {
          display: inline-block;
          width: 16px;
          margin-right: 6px;
          text-align: center;
          font-size: 11px;

          &.e-icon-disallowed {
            font-size: 13px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    order: 2;
    width: 50%;
    padding-left: 16px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 20px;

    .price-info {
      margin-bottom: 22px;
    }

    .items {
      .change-item-btn {
        min-height: 88px;
      }
    }

    .leasing-types {
      margin-bottom: 24px;
    }

    .shipping-wrp {
      .title {
        margin-bottom: 6px;
      }
    }
  }

  // OLD
  .coupon-block {
    h4 {
      margin-top: 25px;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 1.25;
      font-family: $font-family-stihl-bold;
    }

    .form-check {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    textarea {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .modifications {
    .modification_selector {
      @include clearfix();
      margin: 0 -5px;

      .modification {
        position: relative;
        float: left;
        margin: 5px;
        width: 75px;
        height: 75px;
        border: 1px solid $color-lines;
        cursor: pointer;

        &:hover::after, &.active::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: block;
          height: 3px;
          background-color: theme-color();
        }

        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }
    }
  }
}