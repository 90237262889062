#site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-sticky;
  background: $color-white;

  .header-top {
    border-bottom: 1px solid rgba($color-grey4, 0.7);

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 31px;
    }

    .part1 {
      display: flex;
    }

    .part2 {
      display: flex;
    }

    .shops {
      display: inline-block;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-decoration: underline;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color-grey;
      }

      i {
        display: inline-block;
        font-size: 15px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }

    .logo {
      display: inline-flex;
      align-items: center;
      line-height: 0;

      img {
        width: 27px;
        margin-right: 4px;
      }

      span {
        color: $color-black;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
      }
    }

    .header-top-menu {
      column-gap: 24px;
      margin-left: 24px;

      li {
        a {
          position: relative;
          color: $color-black;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 1;

          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-black;
            content: '';
            opacity: 0;
          }

          &.active,
          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .header-bottom {
    border-bottom: 1px solid rgba($color-grey4, 0.7);

    .container {
      display: flex;
      height: 48px;
    }

    .part1 {
      display: flex;
    }

    .part2 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      padding-right: 6px;
    }

    .menu-bars {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 100%;
      padding: 0;
      border: 0;
      background: none;
      color: $color-black;

      &.active {
        .e-icon-close {
          display: block;
        }

        .e-icon-bars {
          display: none;
        }
      }

      i {
        font-size: 14px;
      }

      .e-icon-close {
        display: none;
      }
    }

    .stihl-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90px;
      height: 100%;
      background: $color-orange;

      img {
        width: 65px;
      }
    }

    .compare {
      position: relative;
      display: inline-block;
      padding: 8px;
      font-size: 19px;
      line-height: 0;
      color: $color-black;
      transition: all 0.3s ease-in-out;

      &[aria-expanded="true"] {
        color: $color-grey;
      }

      .total {
        position: absolute;
        top: 2px;
        right: 6px;
        z-index: 1;
        width: 13px;
        height: 13px;
        border-radius: 100%;
        background: $color-orange;
        color: $color-white;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
      }
    }

    .search {
      display: inline-block;
      padding: 8px;
      margin-left: 2px;
      font-size: 16px;
      line-height: 0;
      color: $color-black;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color-grey;
      }
    }

    .user {
      display: inline-block;
      padding: 8px;
      margin-left: 8px;
      font-size: 19px;
      line-height: 0;
      color: $color-black;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color-grey;
      }
    }

    .cart {
      position: relative;
      display: inline-flex;
      align-items: center;
      margin-left: 4px;
      padding: 8px;
      line-height: 0;
      color: $color-black;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color-grey;
      }

      .icon {
        position: relative;
        display: block;
        font-size: 19px;

        .total {
          position: absolute;
          top: -5px;
          right: -4px;
          z-index: 1;
          width: 13px;
          height: 13px;
          border-radius: 100%;
          background: $color-orange;
          color: $color-white;
          text-align: center;
          font-size: 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 15px;
        }
      }

      .txt {
        margin-left: 10px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .main-menu {
      display: flex;
      flex-grow: 1;
      height: 100%;

      li {
        flex-basis: 0;
        flex-grow: 1;
        height: 100%;
        max-width: 200px;
        border-right: 1px solid rgba($color-grey4, 0.7);

        &.search-item {
          max-width: 100%;

          a {
            i {
              font-size: 16px;
            }
          }
        }

        &.products-item {
          a {
            justify-content: center;
            text-transform: uppercase;
            font-family: $font-family-stihl-bold;

            i {
              margin-left: 0;
              margin-right: 8px;
              font-size: 13px;
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          padding-left: 15px;
          padding-right: 15px;
          color: $color-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          &:hover,
          &.active {
            background: $color-grey5;
          }

          &.active {
            .e-icon-plus {
              transform-origin: center center;
              transform: rotate(45deg);
            }
          }

          i {
            margin-left: 5px;
            font-size: 8px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .header-bottom {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  @include media-breakpoint-up(xs) {
    .header-bottom {
      .stihl-logo {
        width: 111px;

        img {
          width: 85px;
        }
      }

      .compare {
        padding: 10px;

        .total {
          top: 4px;
          right: 8px;
        }
      }

      .search {
        padding: 10px;
      }

      .user {
        padding: 10px;
      }

      .cart {
        padding: 10px;
        margin-left: 8px;

        .icon {
          .total {
            top: -6px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .header-top {
      .container {
        height: 40px;
      }

      .part1 {
        order: 2;
      }

      .part2 {
        order: 1;
      }

      .shops {
        font-size: 14px;
        line-height: 24px;

        i {
          font-size: 17px;
        }
      }

      .logo {
        padding-left: 6px;

        img {
          margin-right: 7px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .header-bottom {
      .container {
        height: 75px;
      }

      .part1 {
        flex-grow: 1;
      }

      .part2 {
        flex-grow: 0;
        padding-left: 12px;
        padding-right: 7px;
      }

      .stihl-logo {
        width: 140px;

        img {
          width: 105px;
        }
      }

      .compare {
        margin-left: 11px;

        &:hover {
          color: $color-grey;
        }

        .total {
          top: 2px;
          right: 5px;
          width: 17px;
          height: 17px;
          font-size: 12px;
          line-height: 19px;
        }
      }

      .cart {
        margin-left: 14px;

        .icon {
          .total {
            top: -8px;
            right: -6px;
            width: 17px;
            height: 17px;
            font-size: 12px;
            line-height: 19px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .header-bottom {
      .stihl-logo {
        width: 172px;

        img {
          width: 132px;
        }
      }

      .main-menu {
        li {
          a {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }
  }
}