//== Typography
$font-family-sans-serif: Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
//$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: 'Calibre', sans-serif !default;
$font-family-heading: 'Calibre', sans-serif !default;

$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h4: ceil(($font-size-base * 1.1)) !default; // ~16px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px