@use "sass:map";

@mixin editor_text_p {
  font-size: 18px;
  line-height: 1.4;
}

.page_editor_banner_right {
  > div {
    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 1;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;

    .modal-body & {
      flex-direction: column;
    }
  }
}

.page_editor_text_center {
  > div {
    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 1;
    }

    &:nth-child(2) {
      order: 3;
    }
  }
}

.page_editor_text_right {
  > div {
    &:nth-child(1) {
      order: 3;
    }

    &:nth-child(2) {
      order: 1;
    }

    &:nth-child(2) {
      order: 2;
    }
  }
}

.editor_type_banner_col2, .editor_type_text_banner, .editor_type_text_banners2, .editor_type_banner_product {
  .banner-img-wrp:not(.keep_proportions) {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
    }
  }

  .banner-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .banner-content-wrp {
    .banner-title {
      margin-top: 30px;
      font-size: 14px;
      line-height: 1.1;

      a {
        position: relative;
        display: inline-block;
        padding-right: 20px;
        color: #000000;

        &:after {
          position: absolute;
          top: 50%;
          right: 5px;
          content: '';
          margin-left: 5px;
          width: 12px;
          height: 12px;
          background: url("/images/frontend/icon-arrow-right-16.svg") no-repeat center/contain;
          transition: transform 0.2s;
          transform: translateY(-50%);
        }

        &:hover {
          text-decoration: none;

          &:after {
            transform: translateY(-50%) translateX(10px);
          }
        }
      }
    }

    button {
      margin-top: 30px;
    }

    &.ha-right {
      text-align: right;

      .banner-title {
        a {
          &:after {

          }
        }
      }
    }

    &.ha-center {
      text-align: center;
    }
  }

  .main-btn-wrp {
    margin-top: 40px;
  }

  .owl-carousel {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 20px;

    .item {
      padding-left: 0;
      padding-right: 0;
    }

    .owl-dots {
      left: -5px;
      text-align: left;
      transform: translateY(100%);

      .owl-dot {
        span {
          width: 6px;
          height: 6px;
          margin: 5px 5px;
        }

        &.active, &:hover {
          span {
            background-color: #000000;
          }
        }
      }
    }
  }
}

.editor_type_text_banner {
  .text-column {
    margin: 1.5em 10px 10px;

    p {
      @include editor_text_p;
    }

    .btn-under-text {
      min-width: 176px;
    }
  }

  @include media-breakpoint-up(md) {
    .text-column {
      margin-top: 0;
    }
  }
}

.editor_type_text_banners2 {
  p {
    @include editor_text_p;
  }
}

.editor_type_banner_product {
  .product_listing {
    margin: 0;
    padding-top: 0;

    .product_element {
      margin: 0;
      width: 100% !important;
    }
  }
}

.editor_type_text {
  position: relative;
  font-size: 18px;
  line-height: 1.4;

  a {
    color: #000000;
    font-weight: 700;
    box-shadow: inset 0 -0.05em #fff, inset 0 -0.15em map.get($theme-colors, 'primary');
  }

  .text-editor-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.editor_type_article_slider {
  .banner_column {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: background .4s;
  }

  .content_column {
    .item {
      display: block;
      font-size: 18px;
      line-height: 1.4;
      color: #000000;

      h3 {
        margin-bottom: 15px;
        font-size: 22px;
        font-weight: 400;
        line-height: 1.1;
        font-family: $font-family-stihl-medium;
      }

      &.active {
        background-position: 0 -100%;
      }
    }
  }

  @media (max-width: 767px) {
    .row {
      margin-right: 0;
      margin-left: 0;
    }
    .banner_column {
      padding-top: 56.25%;
      margin-bottom: 20px;
    }
    .content_column {
      .carousel-inner-x {

        .owl-dots {
          left: -5px;
          text-align: left;
          transform: translateY(100%);

          .owl-dot {
            span {
              width: 6px;
              height: 6px;
              margin: 5px 5px;
            }

            &.active, &:hover {
              span {
                background-color: #000000;
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .content_column {
      .item {
        min-height: 163px;
        padding: 15px 40px 15px 20px;
        background: linear-gradient(to bottom, #fff 50%, #EDEDED 50%) 0 0/200% 200%;
        transition: background-position 400ms, border-color 400ms, color 200ms;

        &:not(:last-child) {
          border-bottom: 1px solid #B1B1B1;
        }
      }
    }
  }
}

.editor_type_subscribe {
  &.full-width {
    padding-left: 0;
    padding-right: 0;

    .subscribe-form-block {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &.with_bg {
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: cover;
    background-position: center center;
  }

  .grecaptcha-badge {
    bottom: 65px !important;
  }
}

.subscribe-form-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: map.get($theme-colors, 'primary');

  .inner-wrp {
    margin-left: auto;
    margin-right: auto;
    max-width: 580px;
  }

  .alert-danger {
    padding-top: 14px;
  }

  .title {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    color: #FFFFFF;
  }

  p {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 1.1;
    font-family: $font-family-base;
    text-align: center;
    color: $white;
  }

  form, .fake-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .email-input-wrp {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 290px;
      width: 100%;
      border-bottom: 1px solid #ffffff;

      input {
        flex-grow: 1;
        padding: 0;
        min-height: 35px;
        font-size: 24px;
        font-family: $font-family-stihl-bold;
        color: $white;
        background: transparent;
        border: none;

        &::placeholder {
          color: #ffffff !important;
          opacity: 0.6 !important;
        }

        &:-ms-input-placeholder, &::-ms-input-placeholder {
          color: #ffffff !important;
        }

        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }

      .group-icon {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        margin-left: 10px;

        &:before {
          content: "";
          background-image: url("/images/frontend/icon-pen-white-32.svg");
          background-size: 16px 16px;
          width: 16px;
          height: 16px;
          display: inline-block;
        }
      }
    }

    .rules-check-wrp {
      label {
        color: #FFFFFF;

        a {
          color: #FFFFFF;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .button-wrp {
      width: 100%;

      .btn {
        height: 60px;
        width: 100%;
        font-size: 14px;
        line-height: 1.1;
        font-family: $font-family-stihl-bold;
        text-transform: uppercase;
        color: $white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    p {
      margin-bottom: 35px;
    }

    form, .fake-form {
      max-width: 400px;

      .email-input-wrp {
        max-width: 390px;

        input {
          min-height: 50px;
          font-size: 30px;
        }

        .group-icon {
          flex-shrink: 0;
          width: 35px;
          height: 35px;
          margin: 0 0 5px 20px;

          &:before {
            background-size: 35px 35px;
            width: 35px;
            height: 35px;
          }
        }
      }

      .button-wrp {
        max-width: 360px;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    form, .fake-form {
      max-width: 500px;

      .email-input-wrp {
        max-width: 460px;

        input {
          font-size: 30px;
        }
      }
    }
  }
}

.editor_type_categories_list {
  .categories-list {
    gap: 10px;
    display: grid;
    margin: 0 -10px;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    @include media-breakpoint-up(md) {
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid map.get($theme-colors, 'grey-light');

      &:hover {
        .img-wrp {
          img {
            transform: scale(1.1);
          }
        }

        h2 {
          &:after {
            transform: translate3d(22px, -50%, 0);
          }
        }
      }

      .img-wrp {
        position: relative;
        width: 100%;
        padding-top: calc((3 / 4) * 100%);
        overflow: hidden;

        &.fake-img {
          background-color: #eeeeee;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: transform .2s;
        }
      }

      h2 {
        display: inline-block;
        position: relative;
        cursor: pointer;
        margin-right: 43px;
        text-align: left;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 0;
        color: map.get($theme-colors, 'black');
        font-family: $font-family-stihl-medium;
        line-height: 1.1;
        hyphens: auto;
        font-size: 14px;
        margin-top: 25px;
        margin-left: 10px;

        &:after {
          content: "";
          background: url("/images/frontend/icon-arrow-right-16.svg") no-repeat center/contain;
          position: absolute;
          height: 16px;
          width: 16px;
          top: 50%;
          left: 100%;
          transform: translate3d(17px, -50%, 0);
          transition: transform .2s;
        }
      }
    }
  }
}

.summary_editor_wrp {
  .summary_editor_wrp_inner {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border: 1px solid #B1B1B1;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .short_description_wrp {
    flex-shrink: 0;
    padding-right: 20px;
    width: 50%;
    border-right: 1px solid #B1B1B1;
    font-size: 15px;
    line-height: 1.4;

    ul {
      margin-bottom: 0;
      padding-left: 15px;

      li {
        padding-bottom: 15px;
      }
    }
  }

  .add2cart_wrp {
    flex-shrink: 0;
    padding-left: 20px;
    width: 50%;
    float: none;

    h4 {
      margin: 1px 0 5px 0;
      font-size: 12px;
      font-family: $font-family-stihl-medium;
      text-transform: uppercase;
    }

    .price_info {
      margin-bottom: 24px;

      .price {
        .current_price {
          font-size: 32px;
          line-height: 1.1;
          font-family: $font-family-stihl-bold;
        }

        .old_price {
          margin: 5px 10px 0 0;
          font-size: 12px;
          text-decoration: none;
        }

        &.has_discount {
          .current_price {
            color: map.get($theme-colors, 'primary') !important;
          }
        }
      }
    }

    #add2cart_button {
      font-family: $font-family-stihl-bold;
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-down(md) {
    .summary_editor_wrp_inner {
      flex-direction: column;
    }

    .short_description_wrp {
      margin-bottom: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #B1B1B1;
      padding-right: 0;
      width: 100%;
      border-right: none;
    }

    .add2cart_wrp {
      padding-left: 0;
      width: 100%;

      .price_info {
        margin-bottom: 20px;
      }
    }
  }
}

.editor_type_subscribe_extended {
  .inner-bg-wrp{
    padding: 35px 16px;

    &.with_bg {
      background-size: cover;
      background-position: center center;
    }
  }

  .subscribe-extented-wrp {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .subscribe-description {
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 1.4;
    color: #ffffff;

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
      line-height: 1.5;
      color: #ffffff;

      br {
        display: none;
      }
    }
  }

  .form-group {
    .form-label {
      display: block;
      font-family: $font-family-stihl-bold;
      text-align: center;
    }
  }

  .form-check-label {
    a {
      color: #000000;
      text-decoration-line: underline;
    }
  }

  .button-wrp {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
  @include media-breakpoint-up(md) {
    .inner-bg-wrp{
      padding-left: 0;
      padding-right: 0;
    }

    .inner-wrp {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    .inner-bg-wrp{
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .subscribe-description {
      h2 {
        br {
          display: inline;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .subscribe-description {
      margin-bottom: 40px;

      h2 {
        font-size: 34px;
      }
    }

    .inner-wrp {
      width: 550px;
    }

    .form-group {
      .form-label {
        font-size: 16px;
      }
    }

    .form-check-label {
      font-size: 14px !important;
    }

    .button-wrp {
      margin-top: 30px;
    }
  }
}

.editor_type_countdown_timer {
  position: relative;
  max-width: 944px;
  padding-top: 25px;
  padding-bottom: 25px;
  background: #1c1c1c;
  overflow: hidden;

  &.with_bg {
    background-size: cover;
    background-position: center center;
  }

  .tick-onended-message {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    line-height: 1.4;

    p {
      margin-bottom: 0;
    }
  }

  .top-element {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: #ef6d00;
    width: 6%;
    height: 100%;
    transform: rotate(30deg);
    transform-origin: top right;
  }

  .bottom-element {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: #ef6d00;
    width: 8%;
    height: 100%;
    transform: rotate(30deg);
    transform-origin: bottom left;
  }

  h2 {
    max-width: 600px;
    margin: 0 auto 15px;
    font-size: 21px;
    color: #ffffff;
    font-family: $font-family-stihl-medium;
    text-align: center;
  }

  .timer-wrp {
    position: relative;
    z-index: 2;
    padding: 15px;
    border: 1px solid #ef6d00;
    background: #1c1c1c;
  }

  .tick {
    font-size: 1rem;
    white-space: nowrap;
    font-family: $font-family-stihl-medium;
    text-align: center;
  }

  .tick-flip-spacer {
    letter-spacing: 0 !important;
  }

  .tick-flip, .tick-text-inline {
    font-size: 2.5em;
  }

  .tick-label {
    margin-top: 1em;
    font-size: 1em;
    font-family: $font-family-base;
  }

  .tick-char {
    width: 1.5em;
  }

  .tick-text-inline {
    display: inline-block;
    text-align: center;
    min-width: 1em;
  }

  .tick-text-inline + .tick-text-inline {
    margin-left: -.325em;
  }

  .tick-group {
    margin: 0 .5em;
    text-align: center;
  }

  body {
    background-color: #fff !important;
  }

  .tick-text-inline {
    color: #595d63 !important;
  }

  .tick-label {
    color: #595d63 !important;
  }

  .tick-flip-panel {
    color: #fff !important;
  }

  .tick-flip-panel-text-wrapper {
    line-height: 1.45 !important;
  }

  .tick-flip-panel {
    background-color: #3c3e3c !important;
  }

  .tick-flip {
    border-radius: 0.12em !important;
  }

  .tick {
    .tick-label {
      display: block;
      margin-top: 5px;
      font-size: 9px;
      text-align: center;
      text-transform: uppercase;
      color: #ef6d00 !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .tick {
      .tick-label {
        font-size: 11px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: 50px;
    padding-bottom: 50px;

    h2 {
      margin-bottom: 25px;
      font-size: 38px;
    }

    .timer-wrp {
      max-width: 600px;
      padding: 30px;
      margin: 0 auto;
    }

    .tick {
      .tick-label {
        margin-top: 10px;
        font-size: 13px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 60px;
    padding-bottom: 60px;

    &.full-width {
      h2 {
        max-width: 944px;
      }

      .timer-wrp {
        max-width: 944px;
        padding: 50px 80px;
      }
    }

    h2 {
      margin-bottom: 30px;
    }

    .tick {
      .tick-label {
        margin-top: 12px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 75px;
    padding-bottom: 100px;
  }
}

// NEW

.page_editor_template,
.collections-index-listing {
  margin-bottom: 64px;

  &.no_margin_bottom {
    margin-bottom: 0;
  }

  .title-wrp {
    margin-bottom: 16px;

    h2 {
      margin: 0 0 8px;
      color: $color-black;
      font-size: 28px;
      font-style: normal;
      font-weight: 900;
      line-height: 32px;
      text-transform: uppercase;
    }

    a {
      display: inline-flex;
      align-items: center;
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -0.28px;
      text-transform: uppercase;
      white-space: nowrap;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color-grey;
      }

      i {
        margin-left: 12px;
        font-size: 13px;
      }
    }
  }

  .list-wrp {
    margin-right: -16px;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;

    .title-wrp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      h2 {
        margin-bottom: 0;
        font-size: 32px;
        line-height: 41px;
      }
    }

    .list-wrp {
      margin-right: 0;
    }
  }
}

.editor_type_categories_slider {
  .list {
    .item {
      a {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 220px;
        height: 260px;
        padding: 8px 10px 24px;
        background: rgba($color-white-darker, 0.4);
        transition: all 0.3s ease-in-out;

        &:hover {
          background: $color-grey5;
        }

        h3 {
          margin: auto 0 0;
          color: $color-black;
          text-align: center;
          font-family: $font-family-base;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 25px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .list {
      .item {
        a {
          width: 248px;
          height: 282px;
          padding: 8px 10px 24px;
        }
      }
    }
  }
}

.editor_type_banner {
  &.no-content {
    .banner-img {
      height: auto !important;
    }
  }

  .banner-inner-wrp {
    position: relative;
    display: block;
    line-height: 0;

    .banner-img {
      width: 100%;
      height: 576px;
      object-fit: cover;
    }

    .banner-content-wrp {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      padding-top: 56px;
      padding-bottom: 56px;

      &.ha-left {
        text-align: left;
      }

      &.ha-right {
        text-align: right;
      }

      &.ha-center {
        text-align: center;
      }

      &.va-top {
        top: 0;
      }

      &.va-bottom {
        top: unset;
        bottom: 0;
      }

      &.va-center {
        top: 50%;
        transform: translateY(-50%);
      }

      &.color-light {
        .banner-title,
        .short-text {
          color: $color-white;
        }
      }

      &.color-dark {
        .banner-title,
        .short-text {
          color: $color-black;
        }
      }

      .stihl-logo {
        width: 94px;
        margin-bottom: 12px;
      }

      .banner-title {
        margin: 0 0 16px;
        font-size: 36px;
        font-style: normal;
        font-weight: 900;
        line-height: 32px;
        text-transform: uppercase;
      }

      .short-text {
        margin-bottom: 0;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .btn-wrp {
        display: block;
        min-width: 112px;
        margin-top: 16px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .banner-inner-wrp {
      .banner-img {
        height: 420px;
      }

      .banner-content-wrp {
        padding-left: 28px;
        padding-right: 28px;

        &.ha-center {
          left: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          transform: translateX(-50%);
        }

        &.ha-right {
          left: unset;
          right: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: right;
        }

        .banner-title {
          max-width: 450px;
        }

        .short-text {
          max-width: 300px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .banner-inner-wrp {
      .banner-img {
        height: 520px;
      }

      .banner-content-wrp {
        padding-left: 56px;
        padding-right: 56px;

        .banner-title {
          margin-bottom: 12px;
          max-width: 715px;
          font-size: 56px;
          line-height: 56px;
        }

        .short-text {
          max-width: 355px;
          font-size: 17px;
          line-height: 20px;
        }

        .btn-wrp {
          min-width: 286px;
          margin-top: 24px;
        }
      }
    }
  }
}

.editor_type_banner_col3 {
  .list {
    display: grid;
    row-gap: 24px;

    .item {
      display: flex;
      flex-direction: column;

      .img-wrp {
        line-height: 0;

        img {
          width: 100%;
          height: 238px;
          object-fit: cover;
        }
      }

      .banner-content-wrp {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 16px;

        .banner-title {
          margin: 0 0 4px;
          font-family: $font-family-base;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;

          a {
            color: $color-black;
          }
        }

        .short-desc {
          margin-bottom: 12px;
          color: $color-black;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .link-btn {
          display: inline-flex;
          align-items: center;
          margin-top: auto;
          color: $color-black;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.28px;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $color-grey;
          }

          i {
            margin-left: 12px;
            font-size: 13px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.banner-col3-carousel {
      .list {
        display: flex;
        row-gap: unset;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 30px;

      .item {
        .img-wrp {
          img {
            height: auto;
          }
        }

        .banner-content-wrp {
          margin-top: 20px;

          .short-desc {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}

.editor_type_banner_col4 {
  .list {
    .item {
      a {
        position: relative;
        display: block;
        height: 299px;

        &:hover {
          h3 {
            .arrow-circle {
              background: $color-black;

              i {
                color: $color-white;
              }
            }
          }
        }

        .img-wrp {
          position: relative;
          height: 100%;

          &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(9deg, rgba(0, 0, 0, 0.50) 38.61%, rgba(0, 0, 0, 0.00) 75.19%);
            content: '';
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h3 {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
          padding: 0 12px 12px;
          margin: 0;
          color: $color-white;
          font-family: $font-family-base;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;

          .arrow-circle {
            margin-left: 10px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .list {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      column-gap: 30px;

      .item {
        a {
          width: 100%;
          height: 380px;

          h3 {
            padding: 0 20px 20px;
          }
        }
      }
    }
  }
}

.editor_type_youtube {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.with_bg {
    padding: 150px 0;
  }

  .video {
    overflow: hidden;
    position: relative;
    padding-top: calc((720 / 1280) * 100%);
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.editor_type_dakar24_products {
  .title-wrp {
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;

      img {
        width: 35px;
        height: auto;
      }
    }
  }

  .description {
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
  }

  .list {
    grid-gap: 30px;
    flex-wrap: wrap;
    margin-top: 30px;

    .product-element {
      width: 100%;
    }

    .banner {
      img {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .list {
      display: grid !important;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .banner {
        grid-column: span 2;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .title-wrp {
      h2 {
        img {
          width: 45px;
        }
      }
    }

    .list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}