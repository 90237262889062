.features-fancybox {
  .fancybox-inner {
    > .fancybox-infobar {
      display: none;
    }
  }

  .fancybox-content {
    padding: 55px 30px;
    max-width: 940px;
  }

  .arrow_left, .arrow_right {
    position: absolute;
    top: 0;
    width: 45px;
    height: 45px;
    padding: 0;

    &:before {
      content: "";
      position: absolute;
      top: 15px;
      display: block;
      height: 15px;
      width: 15px;
      border-right: 2px solid $color-white;
      border-bottom: 2px solid $color-white;
    }
  }

  .arrow_left {
    left: 0;

    &:before {
      left: 17px;
      transform: rotate(135deg);
    }
  }

  .arrow_right {
    left: 46px;

    &:before {
      left: 13px;
      transform: rotate(-45deg);
    }
  }

  .fancybox-close-small {
    display: none;
  }

  .close-features {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 9px;
    border: 0;
    background: none;
    font-size: 14px;
    line-height: 0;
    color: $color-black;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $color-grey5;
    }
  }

  .fancybox-content {
    .fancybox-infobar {
      position: absolute;
      top: 5px;
      left: 110px;
      opacity: 1;
      visibility: visible;
    }
  }

  .feature_el_content {
    display: flex;
    flex-direction: row;

    .img-wrp {
      flex-shrink: 0;
      width: 40%;
      padding: 0 10px;
    }

    .text-wrp {
      h3 {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 20px;
        font-family: $font-family-stihl-medium;
      }

      .feature-description {
        margin-bottom: 30px;
        line-height: 1.4;
        font-size: 15px;
      }
    }
  }

  .fancybox-close-small {
    padding: 7px !important;
    width: 45px;
    height: 45px;
    opacity: 1;

    svg {
      color: $color-white;
    }
  }

  @media (max-width: 767px) {
    .feature_el_content {
      flex-direction: column;

      .img-wrp {
        width: 100%;

        img {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media (min-width: 768px) {
    .feature_el_content {
      .text-wrp {
        padding-left: 40px;

        h3 {
          font-size: 22px;
        }
      }
    }
  }
  @media (min-width: 1024px) {
    .fancybox-content {
      padding: 82px;
    }
  }
}