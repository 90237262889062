#comparison-listing {
  .total {
    margin-bottom: 0;
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .show-only-diff {
    margin-top: 18px;
  }

  .comparison-wrp {
    margin-top: 26px;
  }

  .clone-head-table-wrap {
    .product {
      .purpose,
      .tags,
      .add-to-comparison,
      .top-left-imgs,
      .img-wrapper {
        display: none;
      }
    }
  }

  table {
    table-layout: fixed;

    &:not(.dynamic-rows) {
      tr {
        &.param {
          &:nth-child(even) {
            background: $color-grey9;
          }
        }
      }
    }

    &.dynamic-rows {
      tr {
        &.even {
          background: $color-grey9;
        }
      }
    }

    tr {
      &.param-title {
        td {
          padding-top: 34px;
          padding-bottom: 22px;
          padding-left: 0;
          font-family: $font-family-stihl-bold;
          font-size: 16px;
          line-height: 1;
          text-transform: uppercase;
        }
      }
    }

    th {
      padding: 10px 16px;
      border-top: 1px solid $color-grey3;
      border-bottom: 1px solid $color-grey3;
      font-weight: 400;
    }

    td {
      padding: 10px 16px;
      color: $color-black;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      .param-title {
        display: block;
        font-weight: 700;
      }
    }

    .product {
      vertical-align: top;

      .product-wrp {
        position: relative;
      }

      .add-to-comparison {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        padding: 5px 5px 10px 10px;
        color: $color-black;
        font-size: 14px;
        line-height: 0;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }
      }

      .product-element-link {
        padding: 0;
        border-bottom: 0;
      }

      .stock_status {
        margin-bottom: 7px;
      }

      span.add-to-comparison {
        display: none;
      }
    }

    .side-cell {
      vertical-align: bottom;

      .title {
        display: block;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
      }

      .show-only-diff {
        margin-top: 16px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .comparison-wrp {
      margin-left: -16px;
      margin-right: -16px;
      overflow: auto;
    }

    table {
      width: 150%;

      tr {
        &.param-title {
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    table {
      tr {
        &.param {
          td {
            &:first-child {
              padding-left: 20px;
            }
          }
        }

        td, th {
          &:first-child {
            border-right: 1px solid $color-grey3;
          }
        }

        th {
          width: 25%;
          padding-left: 30px;
          padding-bottom: 24px;
        }
      }

      td {
        padding: 18px 20px 18px 30px;
        font-size: 15px;

        .param-title {
          font-weight: 400;
        }
      }
    }
  }
}