@use "sass:map";

.fancybox-content.leasing-modal {
  min-width: 100%;
  padding: 60px 15px 50px;

  .fancybox-button {
    display: none;
  }

  .close-leasing-modal {
    position: absolute;
    top: 25px;
    right: 20px;
    z-index: 1;
    display: block;
    width: 15px;
    height: 15px;
    background: url("/images/frontend/icon-close.svg") no-repeat center center;
    background-size: contain;
  }

  .title {
    display: block;
    margin-bottom: 24px;
    font-family: $font-family-stihl-bold;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: map.get($theme-colors, 'black');
  }

  iframe {
    width: 100%;
    border: 0;
  }

  #sblizingas-frame {
    height: 310px;
  }

  #mokilizingas-frame {
    height: 90px;
  }

  @include media-breakpoint-up(xl) {
    padding: 50px 60px;
    min-width: unset;
    max-width: 704px;
  }
}