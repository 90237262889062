$font-family-base: 'STIHL-Contraface-Text', 'Arial', sans-serif;

$font-family-stihl-medium: 'STIHL-Contraface-Display-Medium', 'Arial Black', sans-serif;
$font-family-stihl-bold: 'STIHL-Contraface-Display-Title', 'Arial Black', sans-serif;

$color-black: #000000;
$color-white: #FFFFFF;
$color-white2: #F4F4F4;
$color-white-darker: #EAEAEA;
$color-orange: #F16F1D;
$color-grey: #6E757C;
$color-grey2: #C8C8C8;
$color-grey3: #A8A8A8;
$color-grey4: #B1B1B1;
$color-grey5: #EDEDED;
$color-grey6: #8B8B8B;
$color-grey7: #D9D9D9;
$color-grey8: #7E7E7E;
$color-grey9: #F7F7F7;
$color-grey10: #DEE2E6;
$color-grey11: #E3E3E3;
$color-grey12: #E0E0E0;
$color-green: #699541;
$color-green2: #DEF1DE;
$color-green3: #306030;
$color-red: #d43b3b;
$color-red2: #E73B3B;
$color-red3: #FDD7D7;
$color-yellow: #FFF3C6;
$color-yellow2: #BC9610;
$color-blue: #E0DDFF;
$color-blue2: #2B1FB2;

$theme-colors: (
        "primary": #F37A1F,
        "danger": #fa0f3d,
        "success": #5cb85c,
        "info": #5bc0de,
        "warning": #f0ad4e,
        "black-light": #08121F,
        "black": #000000,
        "grey-light": #B1B1B1,
        "grey-darker": #8D918D,
        "white": #FFFFFF,
        "white-darker": #EAEAEA,
);

$border-radius: 0 !default;

$font-size-base: 0.875rem !default;
$line-height-base: 1.42857143;

$container-fluid-width: 992px;
$header-height: 46px;
$grid-gutter-width: 32px;

$grid-breakpoints: (
        xxs: 0,
        xs: 360px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1312px,
);