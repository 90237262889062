#orders-pay {
  #cart-vouchers {
    margin-bottom: 56px;
  }

  #order-payment-block {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-bottom: 56px;

    .payment-option {
      position: relative;
      flex-wrap: wrap;
      padding: 16px 12px 16px 46px;
      background: rgba($color-grey5, 0.5);

      .payment-option-box {
        position: absolute;
        top: 16px;
        left: 16px;
      }

      .text {
        flex-grow: 1;
      }

      .payment-logo {
        display: flex;
        align-items: center;
        column-gap: 7px;
        max-width: 80px;
        margin-left: 10px;

        img {
          max-width: 150px;
          max-height: 100%;
        }
      }

      .payment-list {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 16px;

        .payment-type {
          color: $color-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &.active {
            .box {
              border-width: 5px;
            }
          }
        }
      }

      .payment-desc {
        display: block;
        color: $color-black;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        *:last-child {
          margin-bottom: 0;
        }

        p {
          margin-bottom: 16px;
        }

        .payment-desc-logo {
          display: block;
          margin-bottom: 16px;

          img {
            max-width: 100px;
            height: auto;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    #order-payment-block {
      margin-top: 32px;
      margin-bottom: 48px;

      .payment-option {
        padding-left: 56px;

        .payment-option-box {
          top: 19px;
          left: 20px;
        }

        .payment-list {
          padding-top: 6px;
          padding-bottom: 20px;

          .payment-type {
            .box {
              margin-right: 18px;
            }
          }
        }
      }
    }
  }
}