#quick-search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  display: none;
  width: 100%;
  height: 100%;
  background: $color-white;

  &.active {
    display: block;
  }

  #quick-search-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: none;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.3);
  }

  .search-info {
    margin-bottom: 0;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    .spinner-border {
      margin-right: 8px;
    }
  }

  .quick-search-inner {
    position: relative;
    z-index: 2;
    height: 100%;
    padding-top: 11px;
  }

  #quick-search-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  #search-suggestions-results {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding-bottom: 32px;
    overflow: auto;

    .btn-wrp {
      margin-top: 32px;

      .button {
        width: 100%;
      }
    }

    h2 {
      margin: 0 0 16px;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-transform: uppercase;
    }

    .suggestions {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      a {
        display: inline-block;
        margin-bottom: 24px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        transition: all 0.3s ease-in-out;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: $color-grey;
        }
      }
    }

    .products {
      .product-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: block;
        }

        .img-wrp {
          margin-right: 12px;
          flex-shrink: 0;
          line-height: 0;

          img {
            width: 70px;
            height: 67px;
            object-fit: contain;
          }
        }

        .details {
          display: flex;
          flex-direction: column;

          .name {
            margin-bottom: 4px;
            color: $color-black;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
          }

          .category {
            margin-bottom: 5px;
            color: $color-black;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          .price-info {
            display: flex;
            flex-direction: column;

            .price {
              color: $color-black;
              font-size: 15px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;

              &.discount {
                color: $color-orange;
              }
            }

            .old-price {
              color: $color-black;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }

  .btn-close {
    display: inline-block;
    padding-bottom: 2px;
    border-bottom: 1px solid $color-black;
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-grey;
      border-color: $color-grey;
    }
  }

  .search-input-wrp {
    display: flex;
    align-items: flex-end;

    .btn-close {
      margin-left: 16px;
      margin-bottom: 8px;
    }

    .search-input {
      position: relative;
      width: 100%;

      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        font-size: 17px;
        color: $color-black;
      }

      .clear-input {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 100%;
        padding: 0;
        border: 0;
        background: 0;
        font-size: 13px;
        color: $color-black;

        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }
      }

      input {
        height: 46px;
        padding-left: 24px;
        padding-right: 24px;
        border-bottom: 1px solid $color-black;
        background: transparent;
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .btn-close {
      position: absolute;
      top: 27px;
      right: 24px;
    }

    #quick-search-overlay {
      display: block;
    }

    .quick-search-inner {
      position: relative;
      height: 80%;
      padding-top: 0;
      background: $color-white;
    }

    #quick-search-form {
      max-width: 657px;
      padding-top: 36px;
      padding-bottom: 36px;
    }

    #search-suggestions-results {
      display: grid;
      grid-gap: 40px 50px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      height: 100%;
      margin-top: 56px;
      padding-bottom: 0;

      .suggestions {
        margin-bottom: 0;

        a {
          margin-bottom: 12px;
        }
      }

      .products {
        h2 {
          margin-bottom: 36px;
        }

        .product-item {
          margin-bottom: 20px;

          .img-wrp {
            margin-right: 20px;

            img {
              width: 80px;
              height: 77px;
            }
          }

          .details {
            .name {
              margin-bottom: 0;
            }

            .category {
              margin-bottom: 8px;
            }

            .price-info {
              flex-direction: row;
              align-items: normal;

              .old-price {
                margin-left: 4px;
              }
            }
          }
        }
      }

      .btn-wrp {
        grid-column: span 2;
        width: 100%;
        margin-top: 0;
        text-align: center;

        .button {
          width: auto;
          min-width: 180px;
        }
      }
    }
  }
}