.tns-nav {
  display: flex;
  justify-content: center;
  grid-gap: 4px 8px;
  margin-top: 30px;

  &.size-2 {
    button {
      width: 67px;
    }
  }

  button {
    display: flex;
    align-items: center;
    width: 17px;
    height: 6px;
    padding: 0;
    border: 0;
    background: none;

    &.tns-nav-active {
      span {
        background: $color-black;
      }
    }

    span {
      display: block;
      width: 100%;
      height: 2px;
      background: rgba($color-grey4, 0.7);
      transition: all 0.3s ease-in-out;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 25px;
    grid-gap: 2px 8px;

    button {
      width: 36px;
      height: 8px;
    }
  }
}

.tns-controls {
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-50%);
  pointer-events: none;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 0;
    border-radius: 100%;
    background: linear-gradient(to bottom, $color-black 50%, $color-orange 50%) 0 0/200% 200%;
    box-shadow: 0 4px 20px 0 rgba($color-black, 0.1);
    pointer-events: all;
    transition: background-position 400ms, border-color 400ms, color 200ms;

    &[data-controls="prev"] {
      transform: translateX(-50%);
    }

    &[data-controls="next"] {
      transform: translateX(50%);
    }

    &:hover {
      background-position: 0 -100%;
    }

    &[disabled] {
      opacity: 0;
    }

    i {
      font-size: 20px;
      color: $color-white;
    }
  }
}

.tns-outer {
  position: relative;
  line-height: 0;
}