@use "sass:map";

#banners_hero {
  position: static;
  margin-bottom: 66px;
  background: transparent;

  &.full-width {
    .item {
      .container-fluid {
        position: unset;
        max-width: $container-fluid-width;
      }
    }

    .hero-container {
      max-width: none;
      padding: 0;

      #hero_desktop_slider, #hero_mobile_slider {
        max-width: none;
      }
    }
  }

  .container-fluid {
    position: relative;
  }

  #hero_desktop_slider, #hero_mobile_slider {
    margin: 0 auto;
    max-width: $container-fluid-width;
    overflow: hidden;

    .item {
      &:not(:first-child) {
        display: none;
      }

      .banner-fill-link {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      .banner_img {
        min-height: calc(100vh - 159px);
        background-size: cover;
        background-position: center;
      }

      .title_wrp {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .vcenter {
          display: flex;
          height: 100%;

          &.ha-left {
            justify-content: flex-start;
          }

          &.ha-center {
            justify-content: center;
          }

          &.ha-right {
            justify-content: flex-end;
            text-align: right;

            .banner_content {
              h1, .title {
                &:after {
                  margin-left: auto;
                }
              }
            }
          }

          &.va-top {
            align-items: flex-start;
          }

          &.va-middle {
            align-items: center;
          }

          &.va-bottom {
            align-items: flex-end;
          }

          &:before {
            display: none;
          }
        }

        .banner_content {
          position: relative;
          margin-top: 50px;
          margin-bottom: 50px;
          padding: 30px;
          vertical-align: middle;
          white-space: initial;

          .banner_tag {
            position: absolute;
            top: 0;
            left: 0;
            padding: 6px 10px;
            font-size: 12px;
            color: $white;
            font-family: $font-family-stihl-bold;
            text-transform: uppercase;
            background-color: map.get($theme-colors, 'primary');
            transform: translateY(-100%);
          }

          h1, .title {
            margin-top: 0;
            margin-bottom: 20px;
            padding-bottom: 0;
            font-size: 32px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            font-family: $font-family-stihl-bold;
            line-height: normal;
            letter-spacing: 0.8px;

            &:after {
              content: '';
              display: block;
              margin-top: 11px;
              width: 20px;
              height: 4px;
              background-color: #000000;
            }
          }

          .desc {
            margin: 20px 0;
            font-size: 15px;
            line-height: 1.4;
          }

          .banner_links_wrp {
            a {
              display: block;
              margin-right: 30px;
              margin-bottom: 20px;
              font-size: 14px;
              line-height: 1.1;
              font-family: $font-family-stihl-bold;

              &:after {
                position: absolute;
                content: '';
                margin-left: 5px;
                width: 12px;
                height: 12px;
                background: url("/images/frontend/icon-arrow-right-16.svg") no-repeat center/contain;
                transition: transform 0.2s;
              }

              &:hover {
                text-decoration: none;

                &:after {
                  transform: translateX(10px);
                }
              }
            }
          }

          &.theme_light {
            background-color: $white;
          }

          &.theme_dark {
            background-color: #000000;

            h1, .title {
              &:after {
                background-color: $white;
              }
            }

            .banner_links_wrp {
              a {
                &:after {
                  background: url("/images/frontend/icon-arrow-right-white-16.svg") no-repeat center/contain;
                }
              }
            }
          }
        }
      }

      .carousel-control {
        position: absolute;
        top: 50%;
        margin-top: -30px;
        width: 50px;
        height: 60px;
        filter: none;
        font-size: 40px;
        line-height: 60px;
        text-align: center;

        .icon {
          position: static;
          margin: 0;
          width: auto;
          height: auto;
          line-height: inherit;

          &:before {
            margin: 0;
          }
        }
      }

      .prev_btn, .next_btn {
        display: block;
        position: absolute;
        top: 50%;
      }
    }

    &:not(.owl-container) {
      .carousel-inner-x {
        width: 1000vw;

        .item {
          width: 100vw;
          float: left;
        }
      }
    }

    .carousel-inner-x {
      padding: 0;
    }

    .owl-carousel {
      .owl-dots {
        bottom: 10px;

        @include media-breakpoint-up(md) {
          display: none;
        }

        .owl-dot {
          span {
            width: 18px;
            height: 18px;
            border: 2px solid #ffffff;
            background-color: transparent;
          }

          &.active, &:hover {
            span {
              background-color: #ffffff;
            }
          }
        }

      }
    }

    &:hover {
      .owl-carousel {
        .owl-dots {
          display: block;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 60px;

    #hero_desktop_slider, #hero_mobile_slider {
      .item {
        .title_wrp {
          .banner_content {
            > a:hover {
              text-decoration: none;
            }

            h1 {
              margin-top: 20px;
              margin-bottom: 8px;
              font-size: 32px;
              line-height: 33px !important;
              font-weight: 400;
              letter-spacing: 0;
            }

            .btn {
              min-width: 180px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    #hero_desktop_slider, #hero_mobile_slider {
      .item {
        .title_wrp {
          .banner_content {
            margin-top: 100px;
            margin-bottom: 100px;
            max-width: calc((100% / 6) * 4);
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    #hero_desktop_slider, #hero_mobile_slider {
      .item {
        .title_wrp {
          .banner_content {
            max-width: 50%;
          }
        }
      }
    }
  }
}