#items-select-sidebar {
  position: fixed;
  top: 0;
  right: -16px;
  bottom: 0;
  z-index: $zindex-modal-backdrop;
  padding-top: 48px;
  padding-bottom: 16px;
  max-width: 487px;
  width: 100%;
  background-color: $color-white;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.15s ease-in-out;

  &.open {
    right: 0;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  #sidebar-hide-btn {
    position: absolute;
    top: 14px;
    right: 7px;
    padding: 9px;
    border: 0;
    background: none;
    font-size: 14px;
    line-height: 0;
    color: $color-black;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $color-grey5;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;

    h3 {
      margin: 0 0 25px;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 22px;
      font-style: normal;
      line-height: normal;
    }

    .modification-items-list {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      overflow: auto;
      @include scrollbar();

      .item {
        position: relative;
        display: flex;
        padding: 16px;
        border: 0;
        background: rgba($color-grey5, 0.5);
        text-align: left;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: $color-grey5;
        }

        &.out-of-stock{
          .details{
            opacity: 0.2;
          }
        }

        &.type-status {
          padding-right: 22px;

          .details {
            width: 100%;
            justify-content: space-between;

            .name {
              margin-top: 0;
            }

            .item-price-info {
              margin-top: 0;
            }
          }
        }

        .tags {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .stock-status {
          flex-shrink: 0;
          width: 8px;
          height: 8px;
          margin-top: 17px;
          margin-right: 14px;
          border-radius: 100%;
          background: $color-green;

          &.out-of-stock {
            background: $color-red;
          }
        }

        .img-wrp {
          flex-shrink: 0;
          margin-right: 14px;

          img {
            width: 64px;
            height: 64px;
            object-fit: contain;
          }
        }

        .details {
          .name {
            margin-top: 4px;
            margin-bottom: 4px;
            color: $color-black;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
          }

          .specs {
            display: flex;
            flex-direction: column;
            row-gap: 2px;

            li {
              color: $color-black;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }
          }

          .item-price-info {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            padding-right: 25px;
            margin-top: 6px;
            white-space: nowrap;

            &.discount {
              padding-right: 15px;

              .price {
                color: $color-orange;
              }
            }

            i {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              font-size: 14px;
              color: $color-black;
            }

            .price {
              color: $color-black;
              font-family: $font-family-stihl-bold;
              font-size: 16px;
              font-style: normal;
              line-height: 24px;
            }

            .old-price {
              color: $color-black;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 48px;

    #sidebar-hide-btn {
      top: 21px;
      right: 23px;
    }

    .content {
      padding-left: 32px;
      padding-right: 32px;

      h3 {
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 32px;
      }

      .modification-items-list {
        .item {
          .img-wrp {
            img {
              width: 72px;
              height: 72px;
            }
          }

          .details {
            display: flex;
            align-items: center;
            column-gap: 16px;

            .info {
              max-width: 183px;
            }
          }
        }
      }
    }
  }
}