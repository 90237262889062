#mega-menu-desktop {
  position: fixed;
  top: 117px;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: calc(100% - 117px);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;
  overflow: auto;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  #mega-menu-desktop-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.3);
  }

  .main-drop-menu {
    position: relative;
    z-index: 2;
    border-bottom: 1px solid $color-grey2;
    background: $color-white;
  }

  .submenu-wrp {
    display: none;
    min-height: 496px;

    &.active {
      display: flex;
    }
  }

  .container {
    padding-left: 212px;
  }

  .menu-lvl2 {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: 326px;
    padding-top: 32px;
    padding-bottom: 68px;
    border-right: 1px solid rgba($color-grey4, 0.7);

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 54px;
        padding: 5px 16px;
        color: $color-black;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:hover,
        &.active {
          background: $color-white2;
          font-weight: 700;
        }

        &.highlight {
          font-weight: 700;
        }

        span {
          display: flex;
          align-items: center;

          img {
            width: 47px;
            height: 44px;
            object-fit: contain;
            margin-right: 8px;
          }
        }

        i {
          font-size: 13px;
          margin-left: 5px;
        }
      }
    }
  }

  .menu-lvl3 {
    width: 388px;
    padding-top: 32px;
    padding-bottom: 68px;

    &.multi-cols{
      display: flex;
      width: calc(100% - 326px);

      ul{
        width: 388px;
      }
    }

    ul {
      display: none;
      flex-direction: column;
      row-gap: 8px;

      &.active {
        display: flex;
      }

      li {
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 40px;
          padding: 0 16px 0 32px;
          color: $color-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            background: $color-white2;
            font-weight: 700;

            i {
              opacity: 1;
            }
          }

          &.highlight {
            font-weight: 700;
          }

          span {
            display: flex;
            align-items: center;

            img {
              width: 54px;
              height: 40px;
              object-fit: contain;
              margin-right: 8px;
            }
          }

          i {
            font-size: 13px;
            margin-left: 5px;
            opacity: 0;
          }
        }
      }
    }
  }

  .menu-banner {
    position: relative;
    display: none;
    width: 320px;
    height: 388px;
    margin-top: 32px;
    margin-left: auto;

    &.active {
      display: block;
    }

    &:hover {
      h3 {
        .arrow-circle {
          background: $color-orange;
          color: $color-white;
        }
      }
    }

    .img-wrp {
      position: relative;
      height: 100%;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(9deg, rgba($color-black, 0.5) 38.61%, rgba($color-black, 0) 75.19%);
        content: '';
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h3 {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px 20px;
      margin: 0;
      font-family: $font-family-base;
      color: $color-white;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;

      .arrow-circle {
        margin-left: 16px;
      }
    }
  }
}

#mega-menu-mobile {
  position: fixed;
  top: 81px;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.15s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    #mega-menu {
      left: 0;
    }
  }

  #mega-menu-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.3);
  }

  #mega-menu {
    position: absolute;
    top: 0;
    left: -16px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    height: calc(100% - 81px);
    padding-top: 40px;
    padding-bottom: 28px;
    background: $color-white;
    line-height: 0;
    transition: all 0.15s ease-in-out;
  }

  .main-menu {
    display: grid;
    flex-direction: column;
    row-gap: 24px;

    .type-1 {
      .link-type-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 16px;
        color: $color-black;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }

        i {
          margin-left: 8px;
          font-size: 13px;
        }
      }
    }
  }

  .sites {
    display: grid;
    flex-direction: column;
    row-gap: 10px;
    margin-top: auto;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 0;

    li {
      a {
        display: inline-flex;
        align-items: center;
        color: $color-black;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }

        i {
          margin-left: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .submenu-wrp {
    position: absolute;
    top: 0;
    left: 16px;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: $color-white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.15s ease-in-out;

    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    .submenu-wrp-inner {
      height: 100%;
      overflow: auto;
    }

    .menu-header {
      display: flex;
      align-items: center;
      min-height: 62px;
      padding: 16px;
      border-bottom: 1px solid $color-grey2;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      i {
        margin-right: 8px;
        font-size: 13px;
      }
    }

    .submenu {
      .type-2 {
        .link-type-2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 62px;
          padding: 11px 16px;
          border-bottom: 1px solid $color-grey2;
          color: $color-black;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          transition: all 0.3s ease-in-out;

          &.highlight {
            font-weight: 700;
          }

          &:hover {
            color: $color-grey;
          }

          span {
            display: flex;
            align-items: center;
          }

          img {
            flex-shrink: 0;
            width: 42px;
            height: 40px;
            margin-right: 6px;
            object-fit: contain;
          }

          i {
            margin-left: 8px;
            font-size: 13px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    #mega-menu {
      width: calc(100% - 64px);
    }
  }
}