.bootstrap-select {
  width: 100% !important;
  background: none;

  .btn {
    padding: 20px;
    border: 0;
    border-radius: 0;
    background: $color-grey5;
    font-family: $font-family-base;
    color: $color-black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &.dropdown-toggle {
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &:after {
        margin-left: 0;
        vertical-align: 0;
        border: 0;
        content: "\e906";
        font-size: 7px;
        color: $color-black;
        @include icomoon();
      }

      .filter-option-inner {
        padding-right: 5px;
      }
    }

    &[aria-expanded="true"] {
      &.dropdown-toggle {
        &:after {
          transform-origin: center center;
          transform: rotate(180deg);
        }
      }
    }

    &.bs-placeholder {
      color: $color-grey !important;
    }
  }

  .dropdown-menu {
    min-width: 100% !important;
    max-width: 100%;
    padding: 0;
    margin-top: 7px;
    border-radius: 0;
    border-color: $color-grey;
    box-shadow: none;

    li:hover > .dropdown-item {
      background: $color-grey9;
    }

    .active {
      > .dropdown-item {
        background: $color-grey9;
      }
    }

    li a.opt {
      padding-left: 30px;
    }

    .bs-searchbox{
      .form-control{
        height: 40px;
        padding-left: 12px;
        padding-right: 12px;

        &:focus {
          border-color: #ced4da;
          color: $color-black;
          background: $color-grey5;
          box-shadow: none;
        }
      }
    }
  }

  .dropdown-item {
    padding: 12px 20px;
    color: $color-black;
    font-family: $font-family-base;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .dropdown-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}