@use "sass:map";

#wishlist_list {
  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 10px;
      border-top: 1px solid $color-lines;
      border-bottom: 1px solid $color-lines;

      &.thumb {
        width: 140px;
      }

      &.actions {
        text-align: right;
      }
    }

    // mobile
    @include media-breakpoint-down(md) {
      td {
        &.thumb {
          width: 50%;
        }

        &.name {
          width: 50%;
        }

        &.price {
          width: 30%;
        }

        &.actions {
          width: 70%;
          text-align: right;
        }
      }
    }
  }
}