#subscribers-subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 48px;
  background: $color-orange;

  h4 {
    margin: 0 0 4px;
    color: $color-white;
    text-align: center;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.32px;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    color: $color-white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  #newsletter-form {
    margin-top: 35px;
    width: 100%;

    .email-input-wrp {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        border-radius: 2px;
        background: $color-white;
        content: '';
      }

      span {
        position: absolute;
        top: 50%;
        right: 8px;
        z-index: 1;
        font-size: 28px;
        color: $color-white;
        transform: translateY(-50%);
      }

      input {
        height: 47px;
        padding: 0 40px 0 0;
        color: $color-white;
        font-family: $font-family-stihl-bold;
        font-size: 28px;
        font-style: normal;
        line-height: 29px;
        background: transparent;

        &::placeholder {
          color: $color-white;
        }
      }
    }

    .checkbox-item {
      margin-bottom: 20px;
    }

    .button {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 72px;

    h4 {
      margin-bottom: 8px;
    }

    p {
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
    }

    #newsletter-form {
      max-width: 406px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}