#filters {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 80px;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .filter-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 28px;
    padding-left: 16px;
    padding-right: 16px;

    .close-filter {
      position: absolute;
      top: 12px;
      right: 8px;
      z-index: 1;
      color: $color-black;
      font-size: 14px;
      border: 0;
      padding: 10px;
      background: none;
    }

    h2 {
      margin: 0;
      color: $color-black;
      font-size: 22px;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
    }

    .clear-filter {
      display: inline-flex;
      align-items: center;
      margin-left: 18px;
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color-grey;

        span {
          border-color: $color-grey;
        }
      }

      span {
        display: inline-block;
        border-bottom: 1px solid $color-black;
      }

      i {
        margin-right: 5px;
        font-size: 11px;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .filter-btn-wrp {
    position: absolute;
    bottom: 16px;
    left: 0;
    z-index: 1;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;

    .button {
      width: 100%;
    }
  }

  #filters-list {
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    overflow: auto;
  }

  .filter-group {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-grey7;

    .title {
      font-family: $font-family-base;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $color-black;

        &[aria-expanded="true"] {
          i {
            transform: rotate(180deg);
          }
        }

        i {
          margin-left: 5px;
          font-size: 8px;
          color: rgba($color-black, 0.2);
          transform-origin: center center;
        }
      }
    }

    .filter-group-list {
      ul {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        padding-top: 8px;
        padding-bottom: 8px;

        .checkbox-item {
          &.active {
            .box {
              background: $color-black;

              i {
                opacity: 1;
              }
            }
          }

          .text {
            .total {
              color: $color-grey8;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: static;
    z-index: 1;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 1;
    visibility: visible;

    .filter-header {
      position: static;
      display: flex;
      align-items: center;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 9px;
    }

    #filters-list {
      padding-left: 0;
      padding-right: 0;
    }

    .filter-group {
      .filter-group-list {
        display: block !important;
        height: auto !important;

        ul {
          padding-top: 12px;
        }
      }
    }
  }
}