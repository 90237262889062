#products_listing {
  .page-title-wrp {
    margin-bottom: 14px;

    .page-title {
      margin-bottom: 0;

      .total {
        color: $color-grey6;
      }
    }
  }

  .filter-bar-mobile {
    height: 48px;
    margin-bottom: 12px;

    .inner {
      display: flex;

      &.sticky {
        position: fixed;
        top: 80px;
        left: 0;
        z-index: $zindex-sticky;
        width: 100%;
        background: $color-white;
      }
    }

    .button {
      position: relative;
      z-index: 1;
      width: 50%;
      height: 48px;
      border-color: $color-grey2;
      font-family: $font-family-base;
      color: $color-black;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      text-transform: none;

      &:hover {
        z-index: 2;
        border-color: $color-black;
      }

      &:last-child {
        margin-left: -1px;
      }

      i {
        margin-right: 8px;
        font-size: 14px;
      }
    }
  }

  .products-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 38px 18px;
  }

  .main-description {
    margin-top: 40px;
  }

  @include media-breakpoint-up(md) {
    .products-list {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .main-description {
      margin-top: 100px;
    }
  }
  @include media-breakpoint-up(lg) {
    .page-title-wrp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 38px;

      .sort-desktop {
        margin-left: 30px;

        > .dropdown-menu {
          left: unset !important;
          right: 0;
          transform: translate3d(0, 48px, 0px) !important;
        }

        .btn {
          padding: 12px 16px;
          border: 1px solid $color-grey2;
          background: none;
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          transition: all 0.3s ease-in-out;

          &:hover {
            border: 1px solid $color-black;
          }

          &.dropdown-toggle {
            &:after {
              display: none;
            }
          }

          .filter-option-inner-inner {
            text-align: center;

            &:before {
              margin-right: 6px;
              content: "\e916";
              font-size: 14px;
              color: $color-black;
              @include icomoon();
            }
          }
        }

        .dropdown-menu {
          max-width: unset;
        }
      }
    }

    .products-list {
      grid-gap: 56px 25px;
    }

    #products-and-filters {
      display: flex;
    }

    #filters {
      width: 25.6%;
      padding-right: 40px;
    }

    #products-column {
      width: 74.4%;
    }
  }
  @include media-breakpoint-up(xl) {
    #filters {
      padding-right: 88px;
    }
  }
}