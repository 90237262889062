@use "sass:map";
@import "components/cookie-bar";

#inform-in-stock {
  max-width: 700px;
  padding: 0;

  .subscribe-form-block {
    padding: 40px 20px;
  }
}