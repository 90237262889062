@use "sass:map";

.cookie_bar {
  position: fixed;
  bottom: 0;
  z-index: 4;
  height: auto;
  width: 100%;
  background-color: theme-color();

  p {
    float: none;
    margin: 28px 40px 17px 12px;
    width: auto;
    font-size: 12px;
    color: white;

    a {
      color: white;
      text-decoration: underline;
      text-transform: lowercase;
    }
  }

  .close {
    float: right;
    margin-right: 10px;
    margin-top: 0;
    width: 28px;
    height: 28px;
    background: url('/public/images/frontend/cookie_bar_close.png') no-repeat center center;
    cursor: pointer;
    opacity: 1;
  }

  @include media-breakpoint-down(lg) {
    height: auto;
  }
}

.cookie_bar ~ #footer {
  margin-bottom: 70px;
}