#checkout-confirm {
  #order-information {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    margin-bottom: 56px;

    .block {
      padding: 28px 24px;
      background: $color-grey9;

      .title {
        display: block;
        margin-bottom: 10px;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
      }

      p {
        margin-bottom: 16px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .back-link {
        position: relative;
        color: $color-black;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        letter-spacing: -0.28px;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;

          &:after {
            background: $color-grey;
          }
        }

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: $color-black;
          content: '';
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .cart-items-wrp {
    h2 {
      margin-bottom: 8px;
    }
  }

  #cart-summary {
    margin-top: 32px;
    margin-bottom: 56px;
  }

  @include media-breakpoint-up(md) {
    .page-title {
      margin-bottom: 24px;
    }

    #order-information {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px;

        .title {
          font-size: 24px;
          line-height: 32px;
        }

        .back-link {
          margin-top: auto;
        }
      }
    }

    .cart-items-wrp {
      h2 {
        margin-bottom: 0;
      }
    }

    #cart-summary {
      max-width: 407px;
      margin-top: 40px;
      margin-left: auto;
      margin-bottom: 80px;
    }
  }
}