#checkout {
  margin-top: 13px;
  margin-bottom: 104px;

  .page-title {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  h2 {
    margin: 0 0 24px;
    color: $color-black;
    font-family: $font-family-stihl-bold;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
  }

  h3 {
    margin: 0 0 20px;
    color: $color-black;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .checkout-nav {
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
    margin-top: 44px;

    .back {
      width: 60px;
      padding-left: 0;
      padding-right: 0;
      font-size: 13px;

      span {
        margin-left: 12px;
      }
    }

    .next {
      flex-grow: 1;

      i {
        margin-left: 12px;
        font-size: 13px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-bottom: 120px;

    .checkout-content {
      max-width: 657px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 74px;
    }

    .page-title {
      margin-top: 0;
      margin-bottom: 41px;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    .checkout-nav {
      margin-top: 56px;

      .button {
        min-width: 160px;
      }

      .next {
        flex-grow: 0;
      }
    }
  }
}