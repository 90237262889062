#profile-menu-view {
  padding: 0 !important;
  margin: 0 !important;
  background: $color-white;

  .container-fluid {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  .title {
    display: block;
    margin-bottom: 25px;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
  }

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    li {
      a {
        display: block;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    position: fixed !important;
    top: 80px !important;
    left: 0;
    z-index: $zindex-fixed;
    width: 100%;
    height: calc(100% - 80px);
    overflow: auto;
    transform: none !important;
  }
  @include media-breakpoint-up(lg) {
    width: 337px;

    .container-fluid {
      padding-top: 18px;
      padding-bottom: 16px;
    }
  }
}