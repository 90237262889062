.product-detailed-collection {
  margin-bottom: 64px;

  > .title {
    margin: 0 0 16px;
    color: $color-black;
    font-family: $font-family-stihl-bold;
    font-size: 28px;
    font-style: normal;
    line-height: 32px;
  }

  .list-wrp {
    margin-right: -16px;
    overflow: hidden;
  }

  .list {
    display: flex;
  }

  .product-element {
    flex-shrink: 0;
  }

  @include media-breakpoint-up(lg) {
    .list-desktop {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-gap: 56px 30px;

      &.limited-items {
        &.show {
          .product-element {
            &:nth-child(n+4) {
              display: block;
            }
          }
        }

        .product-element {
          &:nth-child(n+4) {
            display: none;
          }
        }
      }

      .show-all {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 4px;
        padding: 0;
        border: 0;
        background: $color-grey9;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 14px;
        font-style: normal;
        line-height: 20px;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          margin-bottom: 2px;
          border-radius: 100%;
          background: $color-black;
          font-size: 13px;
          line-height: 0;
          color: $color-white;
        }
      }
    }
  }
}