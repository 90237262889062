#sorting-mobile {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 80px;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .sorting-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 28px;
    padding-left: 16px;
    padding-right: 16px;

    .close-sorting {
      position: absolute;
      top: 12px;
      right: 8px;
      z-index: 1;
      color: $color-black;
      font-size: 14px;
      border: 0;
      padding: 10px;
      background: none;
    }

    h2 {
      margin: 0;
      color: $color-black;
      font-size: 22px;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-top: 24px;

    li {
      a {
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.active,
        &:hover {
          font-weight: 700;
        }
      }
    }
  }
}