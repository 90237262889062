#comparison-info-view {
  padding: 0 !important;
  margin: 0 !important;
  background: $color-white;

  .container-fluid {
    padding-top: 27px;
    padding-bottom: 27px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .title {
      color: $color-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
    }

    .total {
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .button {
    margin-top: 32px;
  }

  @include media-breakpoint-down(md) {
    position: fixed !important;
    top: 80px !important;
    left: 0;
    z-index: $zindex-fixed;
    width: 100%;
    height: calc(100% - 80px);
    overflow: auto;
    transform: none !important;
  }
  @include media-breakpoint-up(lg) {
    width: 337px;

    .container-fluid {
      padding-top: 18px;
      padding-bottom: 16px;
    }

    .button {
      margin-top: 16px;
    }
  }
}