[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.e-icon-disallowed:before {
  content: "\e91f";
}

.e-icon-alert:before {
  content: "\e91d";
}

.icon-heart-empty:before {
  content: '\e800';
}

/* '' */
.icon-left-big:before {
  content: '\e801';
}

/* '' */
.icon-right-big:before {
  content: '\e802';
}

/* '' */
.icon-heart:before {
  content: '\e803';
}

/* '' */
.icon-ok:before {
  content: '\e804';
}

/* '' */
.icon-mail:before {
  content: '\e805';
}

/* '' */
.icon-cancel:before {
  content: '\e806';
}

/* '' */
.icon-facebook:before {
  content: '\e807';
}

/* '' */
.icon-filter:before {
  content: '\e808';
}

/* '' */
.icon-instagram:before {
  content: '\e809';
}

/* '' */
.icon-language:before {
  content: '\e80a';
}

/* '' */
.icon-check-1:before {
  content: '\e80b';
}

/* '' */
.icon-cart-1:before {
  content: '\e80c';
}

/* '' */
.icon-search-1:before {
  content: '\e80d';
}

/* '' */
.icon-user:before {
  content: '\e80e';
}

/* '' */
.icon-heart-1:before {
  content: '\e80f';
}

/* '' */
.icon-phone:before {
  content: '\e810';
}

/* '' */
.icon-delete:before {
  content: '\e811';
}

/* '' */
.icon-close-1:before {
  content: '\e812';
}

/* '' */
.icon-location:before {
  content: '\e813';
}

/* '' */
.icon-youtube:before {
  content: '\e814';
}

/* '' */
.icon-check-empty:before {
  content: '\e815';
}

/* '' */
.icon-cancel-circled:before {
  content: '\e816';
}

/* '' */
.icon-cancel-circled-outline:before {
  content: '\e817';
}

/* '' */
.icon-down-1:before {
  content: '\e818';
}

/* '' */
.icon-up-1:before {
  content: '\e819';
}

/* '' */
.icon-right:before {
  content: '\e81a';
}

/* '' */
.icon-left-1:before {
  content: '\e81b';
}

/* '' */
.icon-minus:before {
  content: '\e81c';
}

/* '' */
.icon-plus:before {
  content: '\e81d';
}

/* '' */
.icon-spin-1:before {
  content: '\e81e';
}

/* '' */
.icon-login:before {
  content: '\e81f';
}

/* '' */
.icon-heart-empty-1:before {
  content: '\e820';
}

/* '' */
.icon-spin:before {
  content: '\e901';
}

/* '' */
.icon-check:before {
  content: '\e902';
}

/* '' */
.icon-close:before {
  content: '\e903';
}

/* '' */
.icon-cart:before {
  content: '\e904';
}

/* '' */
.icon-profile:before {
  content: '\e905';
}

/* '' */
.icon-close-lg:before {
  content: '\e906';
}

/* '' */
.icon-search:before {
  content: '\e907';
}

/* '' */
.icon-home:before {
  content: '\e908';
}

/* '' */
.icon-down:before {
  content: '\e909';
}

/* '' */
.icon-up:before {
  content: '\e90a';
}

/* '' */
.icon-left:before {
  content: '\e90c';
}

/* '' */
.icon-filter-1:before {
  content: '\f0b0';
}

/* '' */
.icon-menu:before {
  content: '\f0c9';
}