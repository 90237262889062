#checkout-steps {
  .steps-bar {
    .steps-cnt {
      display: block;
      margin-bottom: 4px;
      color: $color-black;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .bar {
      position: relative;
      height: 3px;
      background: $color-grey5;

      &.step-1 {
        &:after {
          width: 25%;
        }
      }

      &.step-2 {
        &:after {
          width: 50%;
        }
      }

      &.step-3 {
        &:after {
          width: 75%;
        }
      }

      &.step-4 {
        &:after {
          width: 100%;
        }
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: $color-orange;
        content: '';
      }
    }
  }

  .step {
    display: inline-flex;
    align-items: center;
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    &.active {
      font-weight: 700;

      span {
        background: $color-orange;
        color: $color-white;
        font-family: $font-family-base;
        font-weight: 700;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 22px;
      height: 22px;
      margin-right: 2px;
      border-radius: 100%;
      background: $color-grey10;
      color: $color-grey;
      font-family: $font-family-stihl-medium;
      font-size: 16px;
      font-style: normal;
      line-height: 1;
    }
  }

  @include media-breakpoint-up(md) {
    border-bottom: 1px solid $color-grey2;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 32px;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .step {
      font-size: 16px;
      font-family: $font-family-base;

      span {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .container {
      column-gap: 60px;
    }
  }
}