.arrow-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 0;
  border-radius: 100%;
  background: $color-white;
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $color-orange;
  }

  i {
    font-size: 16px;
    color: $color-black;
    transition: all 0.3s ease-in-out;
  }
}