#pages-detailed {
  .page-title {
    margin: 0 0 32px;
  }

  @include media-breakpoint-up(md) {
    .page-title {
      margin-bottom: 41px;
    }
  }
}