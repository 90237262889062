.products-added2cart {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  width: 100%;
  height: 100%;
  background: $color-white;
  overflow: auto;

  .close-btn {
    position: absolute;
    top: 30px;
    right: 16px;
    z-index: 1;
    padding: 10px;
    color: #000;
    font-size: 13px;
  }

  .main-content {
    padding: 37px 16px 32px;
  }

  .header-title {
    margin: 0 0 35px;
    color: $color-black;
    font-family: $font-family-stihl-bold;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;

    i {
      margin-right: 8px;
      font-size: 15px;
      color: $color-orange;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  .btn-wrp {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 30px;
  }

  .product {
    display: flex;
    align-items: flex-start;
    column-gap: 12px;

    .img-wrp {
      flex-shrink: 0;

      img {
        width: 83px;
        height: 80px;
        object-fit: contain;
      }
    }

    .details {
      display: flex;
      flex-wrap: wrap;

      .info {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 17px;

        .name {
          color: $color-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .code {
          color: $color-black;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
        }

        .price-wrp {
          display: flex;
          align-items: center;
          margin-top: 4px;
          white-space: nowrap;

          .price {
            color: $color-black;
            font-family: $font-family-stihl-bold;
            font-size: 12px;
            font-style: normal;
            line-height: 20px;

            &.discount {
              color: $color-orange;
            }
          }

          .old-price {
            margin-left: 6px;
            color: $color-black;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            text-decoration: line-through;
          }
        }
      }

      .qty {
        width: 30%;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
      }

      .summary-price-wrp {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 70%;
        white-space: nowrap;

        .price {
          color: $color-black;
          font-family: $font-family-stihl-bold;
          font-size: 16px;
          font-style: normal;
          line-height: 1;

          &.discount {
            color: $color-orange;
          }
        }

        .old-price {
          margin-left: 6px;
          color: $color-black;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-decoration: line-through;
        }
      }
    }
  }

  .product-offer-wrp {
    padding: 32px 16px;
    border-top: 1px solid $color-grey2;

    .offer-header {
      margin: 0 0 24px;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
    }

    .product-offer {
      padding: 12px;
      border: 1px solid $color-orange;

      .button {
        width: 100%;
        margin-top: 20px;
      }

      .details-wrp {
        display: flex;
        align-items: flex-start;
        column-gap: 8px;
      }

      .img-wrp {
        flex-shrink: 0;
        position: relative;

        .accum-system-logo {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          grid-gap: 5px;

          img {
            width: 42px;
            height: auto;
          }
        }

        img {
          width: 104px;
          height: 104px;
        }
      }

      .details {
        display: flex;
        flex-direction: column;

        .tags {
          margin-bottom: 8px;
        }

        .name {
          color: $color-black;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .category {
          color: $color-black;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
        }

        .stock-status {
          display: block;
          margin-top: 15px;
          margin-bottom: 17px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 8px;
            width: 9px;
            height: 9px;
            background-color: $color-green;
            border-radius: 100%;
          }

          &.out-of-stock {
            &:before {
              background-color: $color-red;
            }
          }
        }

        .price-wrp {
          display: flex;
          flex-direction: column;
          white-space: nowrap;

          .price {
            color: $color-black;
            font-family: $font-family-stihl-bold;
            font-size: 16px;
            font-style: normal;
            line-height: 14px;

            &.discount {
              color: $color-orange;
            }
          }

          .old-price {
            color: $color-black;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            text-decoration: line-through;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 737px;
    height: auto;
    max-height: calc(100vh - 30px);

    .close-btn {
      top: 22px;
    }

    .header-title {
      margin-bottom: 48px;
      font-size: 24px;
      line-height: 32px;
    }

    .main-content {
      padding: 56px 56px 40px;
    }

    .product {
      column-gap: 20px;

      .img-wrp {
        img {
          width: 108px;
          height: 104px;
        }
      }

      .details {
        flex-wrap: nowrap;
        flex-grow: 1;
        align-items: flex-start;

        .info {
          width: auto;
          max-width: 300px;
          margin-bottom: 0;

          .name{
            padding-right: 20px;
          }
        }

        .qty {
          line-height: normal;
        }

        .summary-price-wrp {
          flex-direction: column;
          align-items: flex-end;
          width: auto;

          .price{
            line-height: normal;
          }

          .old-price {
            margin-left: 0;
          }
        }
      }
    }

    .btn-wrp {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 12px;
      margin-top: 24px;

      .view-cart {
        order: 2;
      }

      .continue-shopping {
        order: 1;
      }
    }

    .product-offer-wrp {
      padding: 43px 56px 56px;

      .offer-header {
        margin-bottom: 14px;
      }

      .product-offer {
        padding: 16px 26px 26px 16px;

        .img-wrp {
          img {
            width: 208px;
            height: 202px;
          }
        }

        .details-wrp {
          column-gap: 20px;
        }

        .details {
          flex-grow: 1;
        }

        .button {
          margin-top: 17px;
        }
      }
    }
  }
}