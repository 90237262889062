#cart-summary {
  .col {
    &:last-child {
      padding-left: 0;
    }
  }

  .summary {
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    .row {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .discount {
      color: $color-orange;
    }
  }

  .total {
    margin-top: 23px;
    color: $color-black;
    font-family: $font-family-stihl-bold;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
  }
}