#pagination {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px 12px;

  .pagination {
    display: flex;
    align-items: center;
    width: 100%;

    .arrow {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 42px;
      height: 42px;
      border: 1px solid $color-grey11;
      color: $color-black;
      font-size: 13px;
      line-height: 1;
      transition: all 0.3s ease-in-out;

      &:hover:not(.disabled) {
        border-color: $color-black;
      }

      &.disabled {
        color: $color-grey2;
        pointer-events: none;

        span {
          color: $color-grey2;
        }
      }

      &.prev {
        margin-right: -1px;

        span {
          margin-left: 10px;
        }
      }

      &.next {
        margin-left: -1px;

        span {
          margin-right: 10px;
        }
      }

      span {
        display: inline-block;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 14px;
        font-style: normal;
        line-height: 1;
      }
    }

    .pages {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-grow: 1;
      column-gap: 6px;
      padding: 5px 8px;
      border: 1px solid $color-grey11;

      .page-item {
        &.active,
        &:hover:not(.dots) {
          .page-link {
            background: $color-grey12;
          }
        }

        .page-link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          color: $color-black;
          text-align: center;
          font-family: $font-family-stihl-bold;
          font-size: 14px;
          font-style: normal;
          line-height: 1;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .show-all {
    width: 44%;

    a {
      display: block;
      padding: 12px 16px;
      border: 1px solid $color-grey2;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 12px;
      font-style: normal;
      line-height: 20px;
      text-align: center;
      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: $color-grey;
      }
    }
  }

  .page-sizes {
    width: calc(56% - 12px);

    .bootstrap-select {
      .btn {
        padding: 12px 16px;
        border: 1px solid $color-grey2;
        background: none;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
        transition: all 0.3s ease-in-out;

        &:hover {
          border-color: $color-grey;
        }
      }
    }
  }

  #lazy-loader {
    display: none;
    text-align: center;
  }

  @include media-breakpoint-up(xs) {
    .pagination {
      .arrow {
        width: 48px;
        height: 48px;
      }

      .pages {
        column-gap: 8px;
        padding: 5px 10px;

        .page-item {
          .page-link {
            width: 36px;
            height: 36px;
          }
        }
      }
    }

    .show-all {
      a {
        font-size: 14px;
      }
    }

    .page-sizes {
      .bootstrap-select {
        .btn {
          font-size: 14px;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    column-gap: 8px;
    margin-top: 62px;

    .pagination {
      width: auto;

      .arrow {
        width: auto;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .show-all {
      width: auto;
      margin-left: auto;
    }

    .page-sizes {
      width: auto;
    }
  }
}