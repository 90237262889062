.radio-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  cursor: pointer;

  &.inline {
    display: inline-flex;
  }

  &.white {
    input {
      &:checked ~ .box {
        border-color: $color-black;
      }
    }

    .box {
      border-color: $color-white;
    }

    .text {
      color: $color-white;

      a {
        color: $color-white;
      }
    }
  }

  &.bold {
    .text {
      font-weight: 700;
    }
  }

  input {
    display: none;

    &:checked ~ .box {
      border-width: 5px;
    }
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border-radius: 100%;
    border: 1px solid $color-black;
    flex-shrink: 0;
  }

  .text {
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    a {
      color: $color-orange;
      text-decoration: underline;
    }
  }
}