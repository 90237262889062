#checkout-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-sticky;
  background: $color-white;

  .inner {
    border-bottom: 1px solid $color-grey2;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
    }

    .stihl-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 111px;
      height: 100%;
      background: $color-orange;

      img {
        width: 85px;
      }
    }

    .info {
      margin-left: 10px;
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: right;

      a {
        color: $color-black;
        font-weight: 700;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .inner {
      .container {
        height: 65px;
      }

      .stihl-logo {
        width: 149px;

        img {
          width: 114px;
        }
      }
    }
  }
}