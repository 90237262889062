#orders-detailed {
  padding-top: 46px;

  .page-title {
    margin-bottom: 24px;
  }

  h2 {
    margin: 0 0 24px;
    color: $color-black;
    font-family: $font-family-stihl-bold;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
  }

  #order-information {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    margin: 24px -16px 33px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 48px;
    border-bottom: 1px solid $color-grey2;

    .block {
      *:last-child {
        margin-bottom: 0;
      }

      h4 {
        margin: 0 0 12px;
        color: $color-black;
        font-family: $font-family-stihl-bold;
        font-size: 20px;
        font-style: normal;
        line-height: 24px;
      }

      p {
        margin-bottom: 8px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        a {
          color: $color-orange;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $color-grey
          }
        }

        .bold-link {
          position: relative;
          color: $color-black;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 1;
          letter-spacing: -0.28px;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: $color-grey;

            &:after {
              background: $color-grey;
            }
          }

          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: $color-black;
            content: '';
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  .cart-items-wrp {
    h2 {
      margin-bottom: 8px;
    }
  }

  #cart-summary {
    margin-bottom: 56px;
    padding-top: 32px;
  }

  @include media-breakpoint-up(md) {
    max-width: 876px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 58px;

    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    #order-information {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 48px 80px;
      margin: 41px 0 56px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 72px;

      .block {
        h4 {
          margin-bottom: 16px;
          font-size: 24px;
          line-height: 32px;
        }

        p {
          margin-bottom: 18px;
        }
      }
    }

    .cart-items-wrp {
      h2 {
        margin-bottom: 0;
      }
    }

    #cart-summary {
      max-width: 407px;
      margin-bottom: 56px;
      margin-left: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    #order-information {
      grid-gap: 48px 114px;
    }
  }
}