.product-element-compact {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;

  .info {
    margin-bottom: 0;
    color: $color-black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .img-wrp {
    flex-shrink: 0;
    line-height: 0;

    img {
      width: 80px;
      height: 77px;
      object-fit: contain;
    }
  }

  .details {
    display: flex;
    flex-direction: column;

    .name {
      margin: 0;
      font-family: $font-family-base;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;

      a {
        color: $color-black;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }
      }
    }

    .category {
      margin-bottom: 8px;
      color: $color-black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase;
    }

    .price-info {
      display: flex;
      align-items: center;

      .price {
        color: $color-black;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;

        .price-from {
          font-size: 12px;
          font-weight: 400;
        }

        &.discount {
          color: $color-orange;
        }
      }

      .old-price {
        margin-left: 8px;
        color: $color-black;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        text-decoration: line-through;
      }
    }
  }

  .remove-from-comparison {
    margin-left: auto;
    padding-left: 5px;
    padding-bottom: 5px;
    color: $color-black;
    font-size: 18px;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $color-grey;
    }
  }
}