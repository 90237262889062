.form-item {
  margin-bottom: 16px;

  &.has-error {
    input,
    textarea {
      border: 1px solid $color-red2;
    }

    .checkbox-item,
    .radio-item {
      .box {
        border-color: $color-red2;
      }
    }

    .bootstrap-select {
      .btn {
        padding-top: 19px;
        padding-bottom: 19px;
        border: 1px solid $color-red2;
      }
    }

    .error-msg {
      display: block;
      margin-top: 6px;
      color: $color-red2;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:before {
        display: inline-block;
        margin-right: 8px;
        content: "\e91d";
        @include icomoon();
        transform: translateY(1px);
      }
    }
  }

  .form-label {
    display: block;
    margin-bottom: 6px;
    color: $color-black;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .error-msg {
    display: none;
    width: 100%;
  }

  &.inline {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px 15px;
  }

  @include media-breakpoint-up(md) {
    &.col2 {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > .form-label {
        width: 35%;
        margin-bottom: 0;
      }

      > .form-control {
        width: 65% !important;
      }

      .error-msg {
        margin-left: 35%;
      }
    }
  }
}