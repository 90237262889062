@use "sass:map";

.news_list {
  @include clearfix();
  margin: 5px -5px;

  .news_element {
    float: left;
    display: block;
    margin: 1%;
    padding: 5px;
    width: 23%;
    height: 300px;
    border: 1px solid rgb(172, 172, 172);

    // tablet
    @include media-breakpoint-down(md) {
      width: 22%;
      margin: 1.5%;
    }
    // mobile
    @include media-breakpoint-down(sm) {
      width: 47%;
      &:nth-child(2n+1) {
        clear: both;
      }
    }
    @media (max-width: 320px) {
      width: 100%;
    }

    a {
      display: block;

      &:hover {
        text-decoration: none;
      }

      img {
        width: 100%;
      }
    }

    span {
      display: block;

      &.date {
        position: absolute;
        float: left;
        font-size: 14px;
        color: rgb(144, 144, 144);
      }

      &.category {
        float: left;
        margin: 15px 0;
      }

      &.name {
        padding-top: 5px;
        font-size: 16px;
        font-weight: bold;
        color: rgb(5, 122, 57);
        clear: both;
      }

      &.description {
        position: relative;
        float: left;
        margin-top: 20px;
        font-size: 14px;
        color: $black;
      }
    }
  }
}