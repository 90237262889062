.products-list-banner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  &:hover {
    h3 {
      .arrow-circle {
        background: $color-black;

        i {
          color: $color-white;
        }
      }
    }
  }

  .tag-wrp {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 3;
    width: 88px;
    line-height: 0;

    img {
      width: 100%;
      height: auto;
    }

    .txt {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      display: block;
      width: 100%;
      transform: translateY(-50%);
      text-align: center;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 26px;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.804px;

      &.text {
        font-size: 20px;
      }
    }
  }

  .img-wrp {
    position: relative;
    display: block;
    height: 100%;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(9deg, rgba($color-black, 0.50) 38.61%, rgba($color-black, 0.00) 75.19%);
      content: '';
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    @include media-breakpoint-up(md) {
      grid-column: span 1;
    }
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 0 12px 12px;
    margin: 0;
    color: $color-white;
    font-family: $font-family-base;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    .arrow-circle {
      margin-left: 10px;
    }
  }

  @include media-breakpoint-up(xl) {
    .tag-wrp {
      top: 24px;
      left: 24px;
      width: 108px;
    }

    h3 {
      padding: 0 24px 24px;
    }
  }
}