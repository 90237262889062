.product-element {
  .product-element-link {
    position: relative;
    color: $black;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 14px;
    border-bottom: 1px solid $color-grey4;
    transition: all 0.3s ease-in-out;

    &:hover {
      border-color: $color-black;
    }
  }

  span {
    display: block;
  }

  .top-part {
    position: relative;
  }

  .top-left-imgs {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    column-gap: 10px;
    pointer-events: none;

    .accum-system-logo {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 10px;

      img {
        width: 50px;
        height: auto;
      }
    }

    .lauras {
      width: 50px;
      height: auto;
    }
  }

  .img-wrapper {
    position: relative;
    padding: 0;
    text-align: center;

    img {
      display: inline;
    }

    &.placeholder-wrapper {
      padding: 100% 0 0;
    }
  }

  .tags {
    display: flex;
    min-height: 34px;
    padding-bottom: 10px;
  }

  .title {
    margin-bottom: 8px;
    height: auto;
    text-align: left;

    .product_name {
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .category_name {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .purpose {
    margin-bottom: 12px;
    font-size: 15px;
    line-height: 1.4;
    text-align: left;
  }

  .bottom-part {
    .stock_status {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 22px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;

      &:before {
        content: '';
        display: inline-block;
        margin-right: 8px;
        width: 9px;
        height: 9px;
        background-color: $color-green;
        border-radius: 100%;
      }

      &.out-of-stock {
        &:before {
          background-color: $color-red;
        }
      }
    }

    .price-info-wrp {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .add2cart-icon {
        height: 36px;
        width: auto;
        margin-left: 5px;
      }
    }

    .price_info {
      display: flex;
      flex-direction: column;
      margin: 0;
      height: auto;
      text-align: left;
      line-height: 1.5em;

      .price {
        font-family: $font-family-stihl-bold;
        font-size: 16px;
        font-style: normal;
        line-height: 1;

        .price_from {
          margin-bottom: 3px;
          font-family: $font-family-base;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          color: $color-black;
        }

        &.discount {
          color: $color-orange;
        }
      }

      .old_price {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        text-decoration: line-through;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .product-element-link {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 19px;
    }

    .title {
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .purpose {
      margin-bottom: 16px;
    }

    .tags {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      min-height: unset;
      padding-bottom: 0;
    }

    .bottom-part {
      .stock_status {
        margin-bottom: 18px;
      }
    }
  }
}