@use "sass:map";

#products-detailed {
  .product-block {
    margin-top: -1px;
    margin-bottom: 48px;
    border: 1px solid $color-grey4;
  }

  .collections-list {
    .product-detailed-collection {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modifications-tabs {
    display: flex;
    flex-wrap: wrap;

    .modification {
      display: flex;
      align-items: center;
      max-width: 50%;
      height: 48px;
      padding-left: 12px;
      padding-right: 12px;
      border: 1px solid $color-grey4;
      background: $color-grey5;
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      transition: all 0.3s ease-in-out;

      &:before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 100%;
        background: $color-green;
        transform: translateY(-1px);
      }

      &:first-child {
        margin-left: 0;
      }

      &.out-of-stock {
        &:before {
          background: $color-red;
        }
      }

      &.active {
        border-bottom-color: $color-white;
        background: $color-white;
      }

      .icon-discount {
        flex-shrink: 0;
        margin-left: 5px;
      }
    }
  }

  .photos-wrp {
    padding-top: 23px;

    .header-wrp {
      position: relative;
      margin-bottom: 24px;
      padding-right: 30px;

      .category {
        color: $color-black;
        font-family: $font-family-stihl-medium;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $color-grey;
        }
      }

      .add-to-comparison {
        top: 7px;
        right: -6px;
      }
    }

    .photos-info {;
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 1.2;
      color: $color-grey;
    }

    #products-photos {
      position: relative;

      .tags {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .accum-system-logo {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        column-gap: 10px;
        pointer-events: none;

        img {
          width: 50px;
          height: auto;
        }
      }

      .main-photo {
        cursor: zoom-in;

        a {
          display: none;
          justify-content: center;
          line-height: 0;

          &.active {
            display: flex;
          }
        }
      }

      .thumbs {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 4px;
        margin-bottom: 10px;

        .thumb {
          position: relative;
          line-height: 0;
          flex-shrink: 0;
          cursor: pointer;

          &:after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            border: 1px solid $color-orange;
            content: '';
            opacity: 0;
          }

          &.active {
            &:after {
              opacity: 1;
            }
          }

          img {
            width: 44px;
            height: 44px;
          }
        }
      }
    }
  }

  .summary-wrp {
    &.coupon-wrp {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .modifications-tabs {
      display: none;
    }

    .tags {
      display: none;
    }

    .product-info {
      .short-description {
        margin-top: 32px;
        margin-bottom: 16px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-grey4;

        ul {
          li {
            position: relative;
            padding-left: 16px;
            margin-bottom: 16px;
            color: $color-black;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            &:last-child {
              margin-bottom: 0;
            }

            &:before {
              position: absolute;
              top: 6px;
              left: 0;
              display: block;
              width: 5px;
              height: 5px;
              border-radius: 100%;
              background: $color-black;
              content: '';
            }
          }
        }
      }

      .descriptive-filters {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        margin-top: 16px;
        margin-bottom: 16px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-grey4;

        .item {
          span {
            display: block;
            color: $color-black;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            &.filter-title {
            }

            &.filter-value {
              font-weight: 700;
            }
          }
        }
      }

      .chargers-btn-wrp {
        display: flex;
        align-items: flex-start;
        padding: 10px;
        border: 1px solid $color-orange;

        img {
          width: 12px;
          margin-top: 1px;
          margin-right: 10px;
        }

        .content {
          display: flex;
          flex-direction: column;

          span {
            display: block;
            margin-bottom: 1px;
            color: $color-black;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          a {
            display: inline-block;
            color: $color-orange;
            font-family: $font-family-stihl-bold;
            font-size: 12px;
            font-style: normal;
            line-height: 1;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: $color-grey;
            }
          }
        }
      }

      .safety-text {
        margin-top: 16px;
        margin-bottom: 16px;
        color: $color-black;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        img {
          width: 100%;
          height: auto;
        }

        *:first-child {
          margin-top: 0;
        }

        *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .dealer-locator {
    position: relative;
    padding: 14px 16px 14px 43px;
    margin-top: 16px;
    margin-left: -16px;
    margin-right: -16px;
    background-color: rgba($color-grey5, 0.5);

    .icon {
      position: absolute;
      top: 14px;
      left: 17px;
      font-size: 18px;
    }

    p {
      margin-bottom: 4px;
      color: $color-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    a {
      display: inline-block;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 14px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $color-grey;
      }

      i {
        margin-left: 8px;
        font-size: 13px;
      }
    }
  }

  .product-detailed-desc {
    margin-bottom: 64px;
    color: $color-black;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h2 {
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 20px;
      font-style: normal;
      line-height: 24px;
    }

    p {
      margin-bottom: 28px;
    }

    .iframe-container {
      position: relative;
      padding-bottom: 57.5%;
      margin-top: 28px;
      margin-bottom: 28px;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .product-features {
    margin-bottom: 64px;

    .title {
      margin: 0 0 8px;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 28px;
      font-style: normal;
      line-height: 32px;
    }

    .desc {
      margin-bottom: 21px;
      color: $color-black;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .product-features-items-wrp {
      margin-right: -16px;
      overflow: hidden;
    }

    .item {
      a {
        position: relative;
        display: block;
        height: 280px;

        &:hover {
          h3 {
            .arrow-circle {
              background: $color-black;

              i {
                color: $color-white;
              }
            }
          }
        }

        .img-wrp {
          position: relative;
          height: 100%;

          &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(9deg, rgba(0, 0, 0, 0.50) 38.61%, rgba(0, 0, 0, 0.00) 75.19%);
            content: '';
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h3 {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0 12px 12px;
          margin: 0;
          color: $color-white;
          font-family: $font-family-base;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;

          .arrow-circle {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .technical-details {
    margin-bottom: 64px;

    .title {
      margin: 0 0 16px;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 28px;
      font-style: normal;
      line-height: 32px;
    }

    .technical-details-table-wrp {
      overflow: hidden;

      &.limited-height {
        &.show {
          tr {
            &:nth-child(n+6) {
              display: table-row;
            }
          }
        }

        tr {
          &:nth-child(n+6) {
            display: none;
          }
        }
      }

      table {
        width: 100%;

        tr {
          td {
            padding: 18px 20px;
            color: $color-black;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          .filter-title {
            width: 56%;
          }

          .filter-value {
            width: 44%;
          }

          &:nth-child(odd) {
            background-color: $color-grey9;
          }
        }
      }
    }

    .act-show-technical {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 98px;
      margin-top: 4px;
      padding: 0;
      border: 0;
      background: $color-grey9;
      color: $color-black;
      font-family: $font-family-stihl-bold;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;

      &.active {
        .icon {
          .e-icon-plus {
            display: none;
          }

          .e-icon-minus {
            display: block;
          }
        }

        .text {
          &.less {
            display: block;
          }

          &.more {
            display: none;
          }
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        margin-bottom: 2px;
        border-radius: 100%;
        background: $color-black;
        font-size: 13px;
        line-height: 0;
        color: $color-white;

        .e-icon-minus {
          display: none;
        }
      }

      .text {
        &.less {
          display: none;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .product-block {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @include media-breakpoint-up(md) {
    .photos-wrp {
      padding-left: 0;
      padding-right: 0;

      .photos-info {;
        margin-bottom: 0;
      }

      #products-photos {
        .accum-system-logo {
          img {
            width: 66px;
          }
        }
      }
    }

    .modifications-tabs{
      .modification{
        max-width: 20%;
        height: 56px;
        margin-left: -1px;
      }
    }

    .summary-wrp {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;

      &.coupon-wrp {
        margin-top: 10px;
        padding-top: 0;

        #products-add2cart {
          width: 100%;
          padding: 48px 60px;
        }
      }

      .product-info {
        order: 1;
        width: 50%;
        padding-right: 16px;
        border-right: 1px solid $color-grey4;

        .safety-text {
          margin-bottom: 0;
        }
      }
    }

    .dealer-locator {
      order: 3;
      width: calc(100% + 32px);
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 39px;

    .modifications-tabs {
      display: none;
    }

    .product-detailed-desc {
      margin-bottom: 110px;

      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .product-features {
      margin-bottom: 100px;

      .title {
        font-size: 32px;
        line-height: 41px;
      }

      .product-features-items-wrp {
        margin-right: 0;
        overflow: visible;
      }

      .item {
        a {
          height: 393px;

          h3 {
            padding: 0 20px 26px;
          }
        }
      }
    }

    .technical-details {
      max-width: 844px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 100px;

      .title {
        font-size: 32px;
        line-height: 41px;
      }

      .technical-details-table-wrp {
        table {
          tr {
            td {
              padding-left: 40px;
              padding-right: 40px;
            }
          }
        }
      }
    }

    .product-block {
      display: flex;
      margin-top: 0;
      border: 0;
    }

    .dealer-locator {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-top: 16px;
      padding-left: 50px;

      .icon {
        top: 16px;
        left: 21px;
      }

      p {
        margin-bottom: 2px;
      }
    }

    .photos-wrp {
      width: 50%;
      padding-top: 0;
      padding-right: 60px;

      .tags {
        display: none;
      }

      .header-wrp {
        margin-bottom: 32px;

        .page-title {
          margin-bottom: 2px;
        }

        .add-to-comparison {
          top: 15px;
          right: 5px;
          font-size: 19px;
        }
      }

      #products-photos {
        .thumbs {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          flex-direction: column;
          flex-wrap: nowrap;
          row-gap: 4px;
          height: 100%;
          margin-bottom: 0;
          overflow: auto;

          .thumb {
            img {
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }

    .summary-wrp {
      position: relative;
      width: 50%;
      margin-top: 56px;
      padding-top: 16px;
      border: 1px solid $color-grey4;

      .modifications-tabs {
        position: absolute;
        top: -56px;
        left: -1px;
        z-index: 1;
        display: flex;
        flex-wrap: nowrap;

        .modification {
          max-width: 300px;
        }
      }

      .tags {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        grid-gap: 0;
      }

      .product-info {
        padding-top: 8px;
        padding-left: 20px;
        padding-right: 20px;

        .short-description {
          margin-top: 0;
          margin-bottom: 25px;
          padding-bottom: 23px;
        }

        .descriptive-filters {
          margin-bottom: 25px;
          padding-bottom: 23px;
        }

        .safety-text {
          margin-top: 24px;
        }
      }
    }
  }
}