@use "sass:map";

#chargers-modal {
  min-width: 100%;
  padding: 60px 15px 50px;

  @include media-breakpoint-up(xl) {
    padding: 0 70px;
    min-width: unset;
    max-width: 1050px;
  }

  .no-chargers-found {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .fancybox-button {
    display: none;
  }

  .move-info {
    position: absolute;
    top: 0;
    left: 30px;
    z-index: 1;
    padding: 0 27px;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .hand {
      width: 55px;
      height: auto;
    }

    .arrow {
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 18px;
      height: auto;

      &.right {
        left: unset;
        right: 0;
      }

      &.left {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }

  .close-chargers-modal {
    position: absolute;
    top: 25px;
    right: 20px;
    z-index: 1;
    display: block;
    width: 15px;
    height: 15px;
    background: url("/images/frontend/icon-close.svg") no-repeat center center;
    background-size: contain;
  }

  .table-wrapper {
    width: 100%;
    overflow: auto;
  }

  table {
    min-width: 1050px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.2;
    color: map.get($theme-colors, 'black-light');

    @include media-breakpoint-up(xl) {
      min-width: unset;
    }

    &.table-mobile {
      min-width: unset;

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    &.table-desktop {
      display: none;

      @include media-breakpoint-up(sm) {
        display: table;
      }
    }

    .text-left {
      text-align: left;
    }

    .header-title-col {
      padding-top: 36px;
      padding-bottom: 15px;

      @include media-breakpoint-up(sm) {
        padding-bottom: 25px;
      }
    }

    .header-title {
      display: block;
      padding-right: 30px;
      font-size: 20px;
      line-height: 28px;
      font-family: $font-family-stihl-bold;
      font-weight: 700;
      color: map.get($theme-colors, 'black');

      @include media-breakpoint-up(xl) {
        padding-right: 75px;
        font-size: 32px;
        line-height: 40px;
      }
    }

    td {
      border-bottom: 1px solid map.get($theme-colors, 'grey-light');
      text-align: center;
    }

    .charger-col {
      width: 129px;
      border-left: 1px solid map.get($theme-colors, 'grey-light');
      border-right: 1px solid map.get($theme-colors, 'grey-light');
      padding: 0 10px;
    }

    .accumulator-row {
      td {
        height: 72px;
      }
    }

    .working-time {
      width: 125px;
      background-color: map.get($theme-colors, 'white-darker');

      span {
        font-weight: 700;
      }
    }

    .col-header-row {
      td {
        border-bottom-color: transparent;
      }

      .charger-col {
        padding-left: 10px;
        padding-right: 10px;
        vertical-align: bottom;
      }
    }

    .product-title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;

      .add-to-cart {
        margin-left: 25px;
      }
    }

    .product-title-col {
      width: 314px;
      padding: 0 16px;
      border-left: 1px solid map.get($theme-colors, 'grey-light');
      text-align: left;

      @include media-breakpoint-up(sm) {
        width: 223px;
      }
    }

    .product-title {
      display: inline-flex;
      align-items: center;

      &.vertical {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 8px;

        img {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }

      &.m-display {
        .name {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      img {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        object-fit: contain;
      }

      picture {
        flex-shrink: 0;
      }

      .name {
        font-family: $font-family-stihl-bold;
        font-weight: 700;
        text-transform: uppercase;
        color: map.get($theme-colors, 'black');

        .working-time-mobile {
          font-family: $font-family-base;
          font-weight: 400;
          text-transform: none;
          margin-top: 5px;

          @include media-breakpoint-up(sm) {
            display: none;
          }

          span {
            font-weight: 700;
          }
        }
      }
    }

    .col-title-row {
      td {
        padding-bottom: 5px;
      }
    }

    .col-title {
      color: map.get($theme-colors, 'grey-darker');
    }

    .cart-col {
      width: 167px;
    }

    .bottom-row {
      td {
        height: 68px;
        border-bottom: 0;
      }

      p {
        color: map.get($theme-colors, 'grey-darker');
        margin: 0;

        span {
          font-weight: 700;
        }
      }
    }

    .add-to-cart {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      min-width: 97px;
      height: 32px;
      padding-left: 8px;
      padding-right: 10px;
      background-color: map.get($theme-colors, 'primary');

      img {
        width: 16px;
        height: auto;
      }

      .price {
        font-family: $font-family-stihl-bold;
        font-weight: 700;
        color: map.get($theme-colors, 'white');
      }
    }
  }
}