.form-control {
  padding: 0 20px;
  height: 60px;
  border: 0;
  background: $color-grey5;
  color: $color-black;
  font-family: $font-family-base;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:focus {
    color: $color-black;
    background: $color-grey5;
    box-shadow: none;
  }

  &::placeholder {
    color: rgba($color-black, 0.4);
  }

  &.white {
    height: 58px;
    border: 1px solid $color-grey2;
    background: $color-white;
    font-size: 16px;

    &:focus {
      border-color: $color-grey;
      background: $color-white;
    }

    &::placeholder {
      color: $color-grey;
    }
  }

  &.bootstrap-select {
    padding: 0;
  }
}